import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useLocation } from 'react-router-dom'
import { queryToParamsObject } from 'shared/helpers/UrlHelper'
import { mapToAppError } from 'Login/helpers/errors'
import ResetPassword from './ResetPassword'
import * as resetPasswordActions from 'Login/modules/resetPassword'

const ResetPasswordContainer = (props) => {
  const location = useLocation()

  useEffect(() => {
    const searchParams = queryToParamsObject(location.search)

    props.setResetPasswordToken(searchParams.reset_password_token)

    if (!!searchParams.error)
      props.setError(mapToAppError('resetPassword', searchParams.error))
  },[])


  const handleFormChange = (inputData) => {
    props.updateInputAndValidateForm(inputData)
  }

  return (
    <ResetPassword
      error={props.error}
      form={props.form}
      onFormChange={handleFormChange}/>
  )
}

function mapStateToProps ({ resetPassword }, props) {
  return resetPassword
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(resetPasswordActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer)
