import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useNavigate, useBlocker, useLocation } from 'react-router-dom'

export default function withPreventNavigation(WrappedComponent) {
  return function preventNavigation(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const [lastLocation, setLastLocation] = useState(null)
    const [confirmedNavigation, setConfirmedNavigation] = useState(false)
    const handleLeave = useRef()

    useEffect(() => {
      if (confirmedNavigation && lastLocation) {
        navigate(lastLocation.location.pathname)
      }
    }, [confirmedNavigation, lastLocation])

    const handleBlockedNavigation = useCallback(
      (nextLocation) => {
        if (
          !confirmedNavigation &&
          nextLocation.location.pathname !== location.pathname
        ) {
          handleLeave.current && handleLeave.current()
          setLastLocation(nextLocation)
          return false
        }
        return true
      },
      [confirmedNavigation, location]
    )

    const preventNavigation = useCallback(() => {
      setConfirmedNavigation(false)
    }, [])

    const confirmNavigation = useCallback(() => {
      setConfirmedNavigation(true)
    }, [])

    const provideLeaveHandler = (handler) => {
      handleLeave.current = handler
    }

    useBlocker(handleBlockedNavigation, !confirmedNavigation)

    return (
      <WrappedComponent
        {...props}
        provideLeaveHandler={provideLeaveHandler}
        preventNavigation={preventNavigation}
        confirmNavigation={confirmNavigation} />
    )
  }
}
