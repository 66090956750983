import React from 'react'
import { Form } from 'shared/UI/Form'
import merge from 'lodash/merge'

const inputs = {
  email: {
    key: 'email',
    autoFocus: false,
    type: Form.InputTypes.email,
    errorMessage: 'Please enter a valid email address',
    label: 'Enter Your Work Email',
    order: 1
  }
}

export default function EnterEmailForm(props) {
  return(
    <div className='enter-email-form'>
      <Form
        {...merge({}, props, { inputs: inputs })}
        submitText={'Next'}>
        {props.children}
      </Form>
    </div>
  )
}
