const isGrecaptchaDefined = () => {
  return typeof window.grecaptcha !== 'undefined'
}

export const executeCaptchaAsync = (recaptchaRef) => {
  if (isGrecaptchaDefined()) {
    return recaptchaRef.current.executeAsync()
  } else {
    return Promise.resolve(null)
  }
}
