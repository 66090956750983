import React from 'react'
import PropTypes from 'prop-types'

import AuthenticityTokenInput from './AuthenticityTokenInput'
import InputForm from './Fields/InputForm'
import FieldsFactory from './Fields/FieldsFactory'

import { Button, BUTTON_SIZES, BUTTON_TYPES, BUTTON_STYLES } from 'shared/UI/Button'

import * as validators from './validators'

import each from 'lodash/each'
import sortBy from 'lodash/sortBy'

const { object, string, func, bool } = PropTypes

Form.InputTypes = {
  email: {
    fieldType: 'text',
    validator: validators.email
  },
  newPassword: {
    fieldType: 'password',
    validator: validators.password
  },
  password: {
    fieldType: 'password',
    validator: validators.required
  },
  requiredString: {
    fieldType: 'text',
    validator: validators.required
  },
  requiredNumber: {
    fieldType: 'number',
    validator: validators.required
  },
  checkbox: {
    fieldType: 'checkbox',
    validator: validators.checked
  },
  hidden: {
    fieldType: 'hidden',
    validator: validators.optional
  },
  optional: {
    fieldType: 'optional',
    validator: validators.optional
  },
  button: {
    fieldType: 'button',
    validator: validators.optional
  }
}

Form.propTypes = {
  isValid: bool.isRequired,
  submitText: string.isRequired,
  inputs: object.isRequired,
  onChange: func.isRequired,
  action: string,
  method: string,
  onSubmit: func
}

export default function Form(props) {
  let form = null
  const handleSubmit = () => {
    if (!props.isValid) {
      return
    }

    let formData = {}

    Object.keys(props.inputs).forEach((fieldName) => {
      formData[fieldName] = props.inputs[fieldName].value
    })

    if (!!props.onSubmit) {
      props.onSubmit(formData, form)
    }
    else {
      form.submit()
    }
  }

  const renderInputs = (inputs) => {
    return sortBy(inputs, 'order').map((field) => {
      const Field = FieldsFactory.getField(field.type.fieldType)
      return (
        <Field
          key={field.key}
          label={field.label}
          type={field.type.fieldType}
          fieldName={field.key}
          name={field.name}
          validate={field.type.validator}
          alertText={field.errorMessage}
          value={field.value}
          placeholder={field.placeholder}
          autoFocus={field.autoFocus}
          onClick={field.onClick}
          onChange={props.onChange}
          className={field.className}
          renderBefore={field.renderBefore}
          renderAfter={field.renderAfter} />
      )
    })
  }

  return (
    <div>
      <form
        className='form'
        ref={(ref) => form = ref}
        method={props.method}
        action={props.action}
        onSubmit={handleSubmit}>
          <AuthenticityTokenInput />
          {renderInputs(props.inputs)}
          {props.children}
          <div className='button-container'>
            <Button
              size={BUTTON_SIZES.medium}
              type={BUTTON_TYPES.primary}
              style={[BUTTON_STYLES.lightText]}
              onClick={handleSubmit}>
              {props.submitText}
            </Button>
          </div>
      </form>
    </div>
  )
}
