import React from 'react'

export default function withPageEndlessScrolling(WrappedComponent, distanceFromBottom) {
  return class extends React.Component {

    componentDidMount() {
      document.addEventListener('scroll', this.handleScroll)
    }

    componentDidUpdate() {
      const {
        hasMorePages, isLoading, shouldFetchNextPage
      } = this.props

      if(hasMorePages && (!isLoading || shouldFetchNextPage)) {
        this.handleScroll(this.props)
      }
    }

    componentWillUnmount() {
      document.removeEventListener('scroll', this.handleScroll)
    }

    provideContainer = (wrappedContainer) => {
      this.wrappedContainer = wrappedContainer
    }

    handleScroll = () => {
      const { isLoading, hasMorePages, onNextPage } = this.props

      if(isLoading || !hasMorePages) {
        return
      }

      const { clientHeight } = document.documentElement
      const containerRect = this.wrappedContainer.getBoundingClientRect()
      const distanceFromVisibleTop = containerRect.top + containerRect.height

      if(distanceFromVisibleTop - distanceFromBottom <= clientHeight) {
        onNextPage()
      }
    }

    render() {

      return (
        <WrappedComponent
          {...this.props}
          provideContainer={this.provideContainer} />
        )
    }
  }
}
