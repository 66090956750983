import React from 'react'
import PropTypes from 'prop-types'
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm'
import { LoginErrorMessage } from 'Login/components'
import ReCAPTCHA from 'react-google-recaptcha'

import './styles.css.scss'

const { object, func, string } = PropTypes

ForgotPassword.propTypes = {
  form: object.isRequired,
  error: string.isRequired,
  onFormChange: func.isRequired,
  onCaptchaChange: func.isRequired
}

export default function ForgotPassword (props) {
  const {
    error,
    form,
    onFormChange,
    onFormSubmit,
    onCaptchaChange
  } = props

  return (
    <div className='forgot-password'>
      <div className='login-action-title'>
        <h1>Forgot your password?</h1>
        <h2>Enter your email address and we will send you a link to reset it</h2>
      </div>
        <ForgotPasswordForm
          {...form}
          onSubmit={onFormSubmit}
          onChange={onFormChange}>
          <div className='captcha-container'>
            <ReCAPTCHA
              sitekey={FollozeState.envConfig.RECAPTCHA_SITE_KEY}
              onChange={onCaptchaChange} />
          </div>
        </ForgotPasswordForm>
        {error && <LoginErrorMessage error={error}/>}
    </div>
  )
}

