import * as api from 'Login/services/api'
import { ERRORS, mapToAppError } from 'Login/helpers/errors'
import { constants as formActionTypes, form } from 'shared/modules/form'
import merge from 'lodash/merge'

const UPDATE_INPUT = 'resetPassword/UPDATE_INPUT'
const VALIDATE_FORM = 'resetPassword/VALIDATE_FORM'
const NEW_PASSWORD_ERROR = 'resetPassword/NEW_PASSWORD_ERROR'
const CLEAN_ERROR = 'resetPassword/CLEAN_ERROR'
const INVALIDATE_FORM = 'resetPassword/INVALIDATE_FORM'

// ACTIONS

function updateInput(args) {
  return {
    ...args,
    type: UPDATE_INPUT
  }
}

function validateForm(args) {
  return {
    ...args,
    type: VALIDATE_FORM
  }
}

function invalidateForm() {
  return {
    type: INVALIDATE_FORM
  }
}

function cleanError() {
  return {
    type: CLEAN_ERROR
  }
}
export function setError(error) {
  return {
    type: NEW_PASSWORD_ERROR,
    error: error
  }
}

export function setResetPasswordToken(token) {
  return function(dispatch) {
    dispatch(updateInput({
      fieldName: 'token',
      value: token,
      isValid: true
    }))
  }
}

function checkPasswordsMatch(form, dispatch) {
  if (form.inputs.newPassword.value != form.inputs.confirmPassword.value) {
    dispatch(invalidateForm())
    dispatch(setError(ERRORS.resetPassword.passwords_must_match))
  }
  else
    dispatch(cleanError())
}

export function updateInputAndValidateForm({ fieldName, value, isValid }) {
  return function (dispatch, getState) {
    dispatch(updateInput({ fieldName, isValid, value }))
    dispatch(validateForm({ fieldName, isValid }))

    const form = getState().resetPassword.form
    if (!!form.isValid)
      checkPasswordsMatch(form, dispatch)
  }
}

// REDUCERS

const initialInputState = {
  isValid: false,
  value: '',
  touched: false
}

const initialInputsState = {
  token: initialInputState,
  newPassword: initialInputState,
  confirmPassword: initialInputState
}

const initialFormState = {
  isValid: false,
  inputs: initialInputsState
}

const initialState = {
  error: '',
  form: initialFormState
}

export default function resetPassword(state = initialState, action) {
  switch(action.type) {
    case INVALIDATE_FORM:
      return merge({}, state, { form: { isValid: false } })
    case NEW_PASSWORD_ERROR:
      return {
        ...state,
        error: action.error
      }
    case CLEAN_ERROR:
      return {
        ...state,
        error: initialState.error
      }
    case UPDATE_INPUT:
      return {
        ...state,
        form: form(state.form, action, formActionTypes.UPDATE_INPUT)
      }
    case VALIDATE_FORM:
      return {
        ...state,
        form: form(state.form, action, formActionTypes.VALIDATE_FORM)
      }
    default:
      return state
  }
}
