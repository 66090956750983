import React from 'react'
import { Provider } from 'react-redux'
import getRoutes from 'Login/config/routes'

export default function Root(props) {
  const { store } = props

  return (
    <Provider store={store}>
      {getRoutes()}
    </Provider>
  )
}
