import React from 'react'

const SENSITIVITY_FACTOR = 20

export default function withEndlessScrolling(WrappedComponent) {
  return class extends React.Component {

    componentDidMount() {
      this.wrappedContainer.addEventListener('scroll', () => this.handleScroll(this.props))
    }

    componentDidUpdate() {
      const { hasMorePages, isLoading } = this.props

      if(!isLoading && hasMorePages) {
        this.handleScroll(this.props)
      }
    }

    componentWillUnmount() {
      this.wrappedContainer.removeEventListener('scroll', () => this.handleScroll(this.props))
    }

    handleScroll = (props) => {
      const wrappedContainer = this.wrappedContainer || {}
      const { scrollTop, scrollHeight, offsetHeight, clientHeight } = wrappedContainer

      if(!clientHeight || props.isLoading || !props.hasMorePages) {
        return
      }

      if(scrollTop + offsetHeight >= scrollHeight - SENSITIVITY_FACTOR) {
        props.onNextPage()
      }
    }

    render() {

      return (
        <WrappedComponent
          {...this.props}
          provideContainer={ref => this.wrappedContainer = ref} />
        )
    }
  }
}
