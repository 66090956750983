import React from 'react'
import { useParams } from 'react-router-dom'

// spreads route params values
const withRouteParamsSpread = (WrappedComponent) => {
  const Wrapper = (props) => {
    const params = useParams()

    return (
      <WrappedComponent
        {...props}
        {...params} />
    )
  }

  return Wrapper
}

export default withRouteParamsSpread