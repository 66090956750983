import * as api from 'Login/services/api'
import { ERRORS, mapToAppError } from 'Login/helpers/errors'
import { REHYDRATE } from 'redux-persist/constants'
import { constants as formActionTypes, form } from 'shared/modules/form'
import { HANDLE_SGININ_FLOW_SUCCESS } from 'Login/modules/enterEmailSignin'
import invert from 'lodash/invert'
import update from 'lodash/update'

const UPDATE_INPUT = 'signin/UPDATE_INPUT'
const VALIDATE_FORM = 'signin/VALIDATE_FORM'
const SIGNIN_ERROR = 'signin/SIGNIN_ERROR'
const SET_EMAIL = 'signin/SET_EMAIL'
const SET_TOKEN = 'signin/SET_TOKEN'
const REQUEST_OTP_SUCCESS = 'signin/REQUEST_OTP_SUCCESS'
const FETCHING_SIGNIN_TOKEN = 'signin/FETCHING_SIGNIN_TOKEN'
const FETCHING_LINKEDIN_USER = 'signin/FETCHING_LINKEDIN_USER'
const FETCHING_LINKEDIN_USER_SUCCESS = 'signin/FETCHING_LINKEDIN_USER_SUCCESS'
export const FETCHING_SIGNIN_TOKEN_SUCCESS = 'signin/FETCHING_SIGNIN_TOKEN_SUCCESS'
export const FETCHING_SIGNIN_TOKEN_FAILURE = 'signin/FETCHING_SIGNIN_TOKEN_FAILURE'

// ACTIONS

function updateInput(args) {
	return {
		...args,
		type: UPDATE_INPUT
	}
}

function validateForm(args) {
	return {
		...args,
		type: VALIDATE_FORM
	}
}

function fetchingSigninToken() {
  return {
    type: FETCHING_SIGNIN_TOKEN
  }
}

function fetchingSigninTokenSuccess() {
  return {
    type: FETCHING_SIGNIN_TOKEN_SUCCESS
  }
}

function fetchingSigninTokenFailure(error) {
  return {
    type: FETCHING_SIGNIN_TOKEN_FAILURE,
    error: error
  }
}

function setToken(token) {
  return {
    type: SET_TOKEN,
    token: token
  }
}

function setEmail(email) {
  return {
    type: SET_EMAIL,
    email
  }
}

function fetchingLinkedinUser() {
  return {
    type: FETCHING_LINKEDIN_USER
  }
}

function fetchingLinkedinUserSuccess(){
  return {
    type: FETCHING_LINKEDIN_USER_SUCCESS
  }
}

function setError(error) {
  return {
    type: SIGNIN_ERROR,
    error: error
  }
}

function requestOtpSuccess(otpData) {
  return {
    ...otpData,
    type: REQUEST_OTP_SUCCESS,
  }
}

function fetchSigninTokenRecord(token) {
  return function (dispatch) {
    dispatch(fetchingSigninToken())

    return api.fetchSigninToken(token)
      .then(({ data }) => {
        dispatch(fetchingSigninTokenSuccess())
        dispatch(setToken(data.token))
        dispatch(updateInput({
          fieldName: 'token',
          value: data.token,
          isValid: true
        }))
        dispatch(validateForm({
          fieldName: 'token',
          isValid: true
        }))

        dispatch(setEmail(data.email))
        dispatch(updateInput({
          fieldName: 'email',
          value: data.email,
          isValid: true
        }))
        dispatch(validateForm({
          fieldName: 'email',
          isValid: true
        }))
      })
      .catch((error) => {
        if (!!error.response) {
          dispatch(fetchingSigninTokenFailure(
            mapToAppError('signin', error.response.data.error)
          ))
        }
        else {
          throw error
        }
      })
  }
}

export function updateInputAndValidateForm({ fieldName, value, isValid }) {
	return function (dispatch) {
		dispatch(updateInput({ fieldName, isValid, value }))
		dispatch(validateForm({ fieldName, isValid }))
	}
}

export function signinError(error) {
  return function (dispatch, getState) {
    const email = getState().signin.email

    dispatch(setError(error))
  }
}

export function requestOtp(email){
  return function (dispatch) {
    return api.requestOtp(email)
      .then(({ data }) => {
        dispatch(requestOtpSuccess(data))
      })
  }
}

export function submitSignin(data) {
  return function(dispatch) {
    return new Promise((resolve) => {
      dispatch(setEmail(data.email))
      resolve()
    })
  }
}

export function setAndFetchSigninToken(token) {
  return function (dispatch) {
    if (!!token) {
      dispatch(setToken(token))
      dispatch(fetchSigninTokenRecord(token))
    }
  }
}
// REDUCERS
const initialInputState = {
  isValid: false,
	value: '',
	touched: false,
}

const initialInputsState = {
  email: initialInputState,
  password: {
    ...initialInputState,
    autoFocus: true,
    label: 'Password'
  },
  token: initialInputState,
  otp: {
    ...initialInputState,
    isValid: true
  },
  resendOtp: {
    ...initialInputState,
    isValid: true
  },
  captcha: {
    ...initialInputState,
    isValid: true
  }
}

const initialFormState = {
	isValid: false,
	inputs: initialInputsState
}

const initialState = {
  otpRequired: null,
  otpRegistrationRequired: false,
  error: '',
  email: '',
  otpSent: false,
  isValidToken: null,
	form: initialFormState,
  isFetchingLinkedinUser: false,
  isFetchingSigninToken: false,
}

export default function signin(state = initialState, action) {
  switch(action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.token
      }
    case SET_EMAIL:
      return {
        ...state,
        email: action.email
      }
    case SIGNIN_ERROR:
      return {
        ...state,
        error: action.error
      }
    case REQUEST_OTP_SUCCESS:
      return {
        ...state,
        otpSent: action.sent
      }
    case HANDLE_SGININ_FLOW_SUCCESS:
      return {
        ...state,
        otpRequired: action.otp_required,
        form: update(state.form, 'inputs.otp.isValid', () => !action.otp_required)
      }
    case FETCHING_LINKEDIN_USER:
      return {
        ...state,
        isFetchingLinkedinUser: true
      }
    case FETCHING_SIGNIN_TOKEN:
      return {
        ...state,
        isFetchingSigninToken: true
      }
    case FETCHING_SIGNIN_TOKEN_SUCCESS:
      return {
        ...state,
        isFetchingSigninToken: false,
        isValidToken: true
      }
    case FETCHING_SIGNIN_TOKEN_FAILURE:
      return {
        ...state,
        isFetchingSigninToken: false,
        isValidToken: false,
      }
    case FETCHING_LINKEDIN_USER_SUCCESS:
      return {
        ...state,
        isFetchingLinkedinUser: false
      }
    case UPDATE_INPUT:
      return {
        ...state,
        form: form(state.form, action, formActionTypes.UPDATE_INPUT)
      }
   	case VALIDATE_FORM:
    	return {
        ...state,
      	form: form(state.form, action, formActionTypes.VALIDATE_FORM)
     	}
    case REHYDRATE:
      return {
        ...state,
        otpRequired: action.payload.enterEmailSignin &&
          action.payload.enterEmailSignin.otpRequired,
      }
    default:
      return state
  }
}
