import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'shared/UI/Form'
import merge from 'lodash/merge'

const inputs = {
  countryCode: {
    key: 'countryCode',
    placeholder: '+1',
    label: 'Your Country code',
    type: Form.InputTypes.requiredString,
    errorMessage: "Country code can't be blank",
    name: 'countryCode',
    className: 'country-code',
    order: 1
  },
  phoneNumber: {
    key: 'phoneNumber',
    placeholder: '5417543010',
    label: 'Phone Number',
    type: Form.InputTypes.requiredString,
    errorMessage: "Phone number can't be blank",
    name: 'countryCode',
    className: 'country-code',
    order: 2
  }
}

export default function OtpRegistrationForm(props) {
  return (
    <div className='signin-form'>
      <Form
        {...merge({}, props, { inputs })}
        onSubmit={props.onSubmit}
        submitText={'Register'}>
        {props.children}
      </Form>
    </div>
  )
}

OtpRegistrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node
}
