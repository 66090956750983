import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'shared/hocs'
import { queryToParamsObject } from 'shared/helpers/UrlHelper'
import  Signin from './Signin'
import { mapToAppError } from 'Login/helpers/errors'
import * as signinActions from 'Login/modules/signin'
import * as accountCreatedActions from 'Login/modules/accountCreated'
import { signin } from 'Login/services/api'
import { NavigationLink } from 'Login/components'
import ReCAPTCHA from 'react-google-recaptcha'
import { executeCaptchaAsync } from 'shared/helpers/RecaptchaHelper'

class SigninContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isCaptchaValid: false
    }

    this.recaptchaRef = createRef()
  }

  componentDidMount() {
    this.props.provideHeaderActions && this.props.provideHeaderActions(NavigationLink)
    this.props.setAndFetchSigninToken(this.props.params.token)
  }

  componentDidUpdate(prevProps) {
    if (this.props.isFetchingSigninToken) {
      return
    }

    if (!this.props.isValidToken) {
      this.props.navigate('/signin')
    }

    const searchParams = queryToParamsObject(this.props.location.search)
    const error = searchParams.error

    if (!!error && !!this.props.email && !prevProps.email) {
      this.props.signinError(mapToAppError('signin', error))
    }
  }

  componentWillUnmount() {
    this.props.signinError('')
    this.props.provideHeaderActions && this.props.provideHeaderActions(null)
  }

  handleResendConfirmation = () => {
    executeCaptchaAsync(this.recaptchaRef).then(token => {
      this.props.resendConfirmation(this.props.email, token)
    })
  }

  handleFormChange = (inputData) => {
    this.props.updateInputAndValidateForm(inputData)
  }

  handleInvalidForm = (error) => {
    this.props.signinError(error)
  }

  handleFormSubmit = (data, targetForm) => {
    if(!this.state.isCaptchaValid) {
      return
    }

    this.props.submitSignin({...data, type: 'email'})
      .then(() => targetForm.submit())
  }

  handleCaptchaChange = (response) => {
    this.setState({isCaptchaValid: !!response})
    this.handleFormChange({
      fieldName: 'captcha',
      value: response,
      isValid: true
    })
  }

  requestOtp = () => {
    this.props.requestOtp(this.props.email)
  }

  handleNavigatBack = () => {
    this.props.navigate('/signin')
  }

  render() {
    const { error, form, otpRequired, email } = this.props

    return (
      <>
        <ReCAPTCHA
          ref={this.recaptchaRef}
          size="invisible"
          sitekey={FollozeState.envConfig.INVISIBLE_RECAPTCHA_SITE_KEY}/>
        <Signin
          email={email}
          error={error}
          form={form}
          onResendConfirmation={this.handleResendConfirmation}
          onFormInvalid={this.handleInvalidForm}
          onFormChange={this.handleFormChange}
          onFormSubmit={this.handleFormSubmit}
          onCaptchaChange={this.handleCaptchaChange}
          onResendOtp={this.requestOtp}
          otpRequired={otpRequired}
          isCaptchaActive={this.state.isCaptchaActive}
          onNavigationBack={this.handleNavigatBack} />
      </>
    )
  }
}

function mapStateToProps({ signin }) {
  return signin
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      ...signinActions,
      ...accountCreatedActions
    },
    dispatch
  )
}

SigninContainer.propTypes = {
  error: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  updateInputAndValidateForm: PropTypes.func.isRequired,
  submitSignin: PropTypes.func.isRequired,
  resendConfirmation: PropTypes.func.isRequired,
  requestOtp: PropTypes.func.isRequired,
  signinError: PropTypes.func.isRequired,
  setAndFetchSigninToken: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  email: PropTypes.string,
  otpRequired: PropTypes.bool,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  provideHeaderActions: PropTypes.func,
  isFetchingSigninToken: PropTypes.bool.isRequired,
  isValidToken: PropTypes.bool.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SigninContainer))
