import React from 'react'
import PropTypes from 'prop-types'
import { CreateAccount, LoginErrorMessage, PasswordValidator, EmailAvatar } from 'Login/components'
import SignupForm from './SignupForm/SignupForm'

import './styles.css.scss'

const { object, func, string } = PropTypes

Signup.propTypes = {
  form: object.isRequired,
  onFormChange: func.isRequired,
  email: string.isRequired
}

export default function Signup(props) {
  const {email, form, onFormChange, onFormSubmit,  error } = props

  return(
    <div className='signup'>
      <div className='login-action-title'>
        <h1>Get started!</h1>
        <h2>Create your account at Folloze.</h2>
      </div>
      <EmailAvatar email={email} onEditEmail={props.onNavigateBack} />
      <SignupForm
        {...form}
        onChange={onFormChange}
        onSubmit={onFormSubmit}>
        <PasswordValidator value={form.inputs.password.value} />
      </SignupForm>
      {error && <LoginErrorMessage error={error}/>}
      <div className='tos'> By clicking "create account" I agree to folloze <br/>
        <span><a href="https://sites.google.com/a/folloze.com/terms-of-service/home" target="_blank">Terms of service</a></span>&nbsp;and&nbsp;
        <span><a href="https://sites.google.com/a/folloze.com/terms-of-service/privacy-policy" target="_blank">Privacy Policy</a></span>
      </div> 
    </div>
  )
}
