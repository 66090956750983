import React from 'react'
import PropTypes from 'prop-types'

import InputForm from './InputForm'
import { InlineInputIcon } from 'shared/UI/Input'

const SECRET_ICONS = {
  hide: 'icon-view-show',
  show: 'icon-view-hide'
}

class SecretInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = { 
      isHidden: true
    }
  }

  onSecretShow = () => {
    this.setState({ 
      isHidden: false
    })
  }

  onSecretHide = () => {
    this.setState({ 
      isHidden: true
    })
  }

  renderInlineIcon = () => {
    return (
       <InlineInputIcon 
        icon={this.state.isHidden ? SECRET_ICONS.hide : SECRET_ICONS.show}
        onIconMouseDown={this.onSecretShow}
        onIconMouseUp={this.onSecretHide}
        onIconMouseOut={this.onSecretHide} />
    )
  }

  render() {
    return (
      <InputForm
        {...this.props}
        type={this.state.isHidden ? 'password' : 'text'}
        renderInlineIcon={this.renderInlineIcon} />
    )
  }
}

export default SecretInput