import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import isFunction from 'lodash/isFunction'

import './style.css.scss'

export const INPUT_SIZES = {
  small: 'flz-input-small',
  medium: 'flz-input-medium'
}

export const INPUT_THEMES = {
  classic: 'classic',
  minimal: 'minimal',
  form: 'form',
  dsm: 'dsm'
}

const Input = (props) => {
  const [internalValue, setInternalValue] = useState(props.value ?? '')

  useEffect(() => {
    if (props.value !== internalValue) {
      setInternalValue(props.value)
    }
  }, [props.value])

  const handleChange = (ev) => {
    const newValue = ev.target.value
    const isValid = validate(newValue)
    if (!isValid) return

    setInternalValue(newValue)
    props.onChange && props.onChange(ev)
  }

  const inputClassNames = classnames(
    'flz-input',
    props.className,
    props.size,
    props.theme,
    {
      disabled: props.disabled
    }
  )
  const visibleContainerClassNames = classnames(
    'flz-input-container',
    props.theme,
    {
      'disabled': props.disabled,
      'input-error': props.errorMessage
    })
  const containerClassNames = props.type === 'hidden' ? 'hidden' : visibleContainerClassNames
  const labelClassName = classnames({ required: props.required })

  const validate = (newValue) => {
    if (isFunction(props.validateValue)) {
      return props.validateValue(newValue)
    }
    return true
  }

  return (
    <>
      <div className={containerClassNames}>
        {props.renderBefore && props.renderBefore()}
        {props.label && <label className={labelClassName}> {props.label} </label>}
        {props.limit && <span className="input-limit">{props.limit}</span>}
        <div className="flz-input-wrapper">
          <input
            id={props.id}
            className={inputClassNames}
            ref={input => { props.inputRef && props.inputRef(input) }}
            value={internalValue}
            defaultValue={props.defaultValue}
            placeholder={props.placeholder}
            name={props.name}
            autoFocus={props.autoFocus}
            type={props.type}
            onClick={props.onClick}
            onChange={handleChange}
            onFocus={props.onFocus}
            onKeyPress={props.onKeyPress}
            onKeyDown={props.onKeyDown}
            onBlur={props.onBlur}
            onInput={props.onInput}
            disabled={props.disabled}
            maxLength={props.maxLength}
            key={props.key}
            step={props.step}
            pattern={props.pattern}
            readOnly={props.readOnly} />
          {props.renderInlineIcon && props.renderInlineIcon()}
        </div>
        {props.renderAfter && props.renderAfter()}
      </div>
      {props.withErrorMessage &&
        <p className="flz-input-error error-message">
          {props.errorMessage &&
            <>
              <span className="icon-error"></span>
              {props.errorMessage}
            </>
          }
        </p>
      }
    </>
  )
}

Input.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onInput: PropTypes.func,
  inputRef: PropTypes.func,
  info: PropTypes.string,
  limit: PropTypes.string,
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  theme: PropTypes.oneOf(Object.values(INPUT_THEMES)),
  withErrorMessage: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  renderBefore: PropTypes.node,
  renderAfter: PropTypes.node,
  renderInlineIcon: PropTypes.node,
  step: PropTypes.number,
  pattern: PropTypes.string,
  validateValue: PropTypes.func
}

Input.defaultProps = {
  size: INPUT_SIZES.medium,
  type: 'text',
  className: '',
  disabled: false,
  theme: INPUT_THEMES.classic,
  withErrorMessage: false,
  errorMessage: '',
  maxLength: -1,
  readOnly: false
}

export default Input
