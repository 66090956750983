import React from 'react'
import PropTypes from 'prop-types'
import pick from 'lodash/pick'
import values from 'lodash/values'
import { triggerClickOnEnter, ACCESSIBILITY_CLASSES } from 'shared/helpers/AccessibilityHelper'

import './style.css.scss'

export const BUTTON_SIZES = {
  small: 'flz-btn-small',
  medium: 'flz-btn-medium',
  large: 'flz-btn-large'
}

export const BUTTON_TYPES = {
  primary: 'flz-btn-primary',
  secondary: 'flz-btn-secondary',
  primaryText: 'flz-btn-primary-text',
  secondaryText: 'flz-btn-secondary-text',
  warning: 'flz-btn-warning',
  alert: 'flz-btn-alert',
  info: 'flz-btn-info'
}

export const BUTTON_STYLES = {
  flat: 'flz-btn-flat', // without 3d effect
  lightText: 'flz-btn-light-text'
}

export default function Button(props) {
  const buttonClassNames = values({
    ...pick(props, ['className', 'size', 'type']),
    style: props.style.join(' '),
    disabled: props.disabled ? 'disabled' : ''
  }).join(' ')

  const handleClick = (e) => {
    props.stopPropagation && e.stopPropagation()

    if (props.disabled) {
      return
    }

    props.onClick && props.onClick(e)
  }

  return (
    <div
      className={`flz-btn ${buttonClassNames} ${ACCESSIBILITY_CLASSES.main}`}
      role='button'
      tabIndex={props.tabIndex}
      onClick={handleClick}
      onKeyDown={triggerClickOnEnter}
      style={props.customStyle}
      data-testid={props['data-testid']}
      aria-label={props['aria-label']}>
      {props.icon && <span className={`btn-icon ${props.icon}`} />}
      {props.children}
      {props.suffixIcon && <span className={`btn-icon-right ${props.suffixIcon}`} />}
    </div>
  )
}

Button.propTypes = {
  'className': PropTypes.string,
  'onClick': PropTypes.func,
  'size': PropTypes.string,
  'type': PropTypes.string,
  'style': PropTypes.arrayOf(
    PropTypes.oneOf([...Object.keys(BUTTON_STYLES).map((k) => BUTTON_STYLES[k])])
  ),
  'customStyle': PropTypes.object,
  'icon': PropTypes.string,
  'suffixIcon': PropTypes.string,
  'disabled': PropTypes.bool,
  'stopPropagation': PropTypes.bool,
  'aria-label': PropTypes.string,
  'data-testid': PropTypes.string,
  'tabIndex': PropTypes.number,
  'children': PropTypes.node
}

Button.defaultProps = {
  className: '',
  size: BUTTON_SIZES.medium,
  type: BUTTON_TYPES.primary,
  style: [],
  disabled: false,
  stopPropagation: true
}
