import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useNavigate, useParams } from 'react-router-dom'
import OtpRegistration from './OtpRegistration'
import * as otpRegistrationActions from 'Login/modules/otpRegistration'

const OtpRegistrationContainer = (props) => {
  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    if (!!props.otpRegistration.otpSent){
      navigate(`/signin/${params.token}`)
    }
  }, [props.otpRegistration.otpSent])

  const handleFormChange = (inputData) => {
    props.updateInputAndValidateForm(inputData)
  }

  const handleInvalidForm = (error) => {
    props.otpRegistrationError(error)
  }

  const handleFormSubmit = (data) => {
    props.createOtpRegistration(data.phoneNumber, data.countryCode)
  }

  return(
    <OtpRegistration
      onFormInvalid={handleInvalidForm}
      onFormChange={handleFormChange}
      onFormSubmit={handleFormSubmit}
      form={props.otpRegistration.form}
      error={props.otpRegistration.error}
    />
    )
}

function mapStateToProps ({ otpRegistration }) {
  return { otpRegistration }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(otpRegistrationActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtpRegistrationContainer)