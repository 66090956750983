import React from 'react'

export default function ngify(WrappedComponent) {

  return class extends React.Component {

    constructor(props) {
      super(props)
    }

    componentDidMount = () => {
      const { bootstrap, ngContainer, params } = this.control

      bootstrap(ngContainer, {
        ...params,
        provideDestroyCallback: this.provideDestroyCallback
      })
    }

    componentWillUnmount = () => {
      this.onDestroy && this.onDestroy()
    }

    provideControl = (control) => {
      this.control = control
    }

    provideDestroyCallback = (destroyCallback) => {
      this.onDestroy = destroyCallback
    }

    render = () => {

      return (
        <WrappedComponent
          {...this.props}
          provideControl={this.provideControl} />
      )
    }
  }
}
