import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import { persist as accountCreatedPersist } from 'Login/modules/accountCreated'
import { persist as enterEmailSigninPersist } from 'Login/modules/enterEmailSignin'

import Honeybadger from 'honeybadger-js'
import ErrorBoundary from '@honeybadger-io/react'

import Root from './Root'
import * as reducers from 'Login/modules'
import {
  setRegistrationToken, persist as signupPersist
} from 'Login/modules/signup'

const enhancer = compose(
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__ && FollozeState.envConfig.NODE_ENV !== 'production' ?
    window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
)

const store = createStore(
  combineReducers({...reducers}),
  enhancer
)

const transforms = {
  signup: signupPersist,
  accountCreated: accountCreatedPersist,
  enterEmailSignin: enterEmailSigninPersist
}

const sanitaizeInputs = {
  in: (state, key) => {
    return transforms[key](state)
  },
  out: (state, key) => {
    return state
  }
}

persistStore(store, {
  transforms: [sanitaizeInputs],
  whitelist: ['signup', 'accountCreated', 'enterEmailSignin']
})


const honeybadger = Honeybadger.configure({
  api_key: FollozeState.envConfig.HONEYBADGER_API_KEY,
  environment: FollozeState.envConfig.NODE_ENV
})

Honeybadger.beforeNotify(() => false) // Disable error notifications until we'll fix source maps

ReactDOM.render(
  <ErrorBoundary honeybadger={honeybadger}>
    <AppContainer>
      <Root store={store} />
    </AppContainer>
  </ErrorBoundary>,
  document.getElementById('login-app')
)

if (module.hot) {
  module.hot.accept('./Root', () => {
    const NewRoot = require('./Root').default

    ReactDOM.render(
      <ErrorBoundary honeybadger={honeybadger}>
        <AppContainer>
          <NewRoot store={store} />
        </AppContainer>
      </ErrorBoundary>,
      document.getElementById('login-app')
    )
  })
}
