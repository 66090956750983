import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, BUTTON_SIZES, BUTTON_TYPES, BUTTON_STYLES } from 'shared/UI/Button'

import './styles.css.scss'

const ForgotPasswordSuccessPage = (props) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/signin', { replace: true })
    return
  }

  return(
    <div className='forgot-password-success-page'>
      <div className='login-action-title'>
      <h1>Thank You!</h1>
      <h2>Please check your email, we have sent you instructions for resetting your password.</h2>
      </div>
      <div className='alert'>
        If you don't see the reset email. please check your spam folder.
      </div>
      <div className='button-container'>
        <Button
          size={BUTTON_SIZES.medium}
          type={BUTTON_TYPES.primary}
          style={[BUTTON_STYLES.lightText]}
          onClick={handleClick}>
          Back to Login
        </Button>
      </div>
    </div>
  )
}

export default ForgotPasswordSuccessPage
