import React from 'react';
import PropTypes from 'prop-types'
import EnterEmail from './EnterEmail'

const { object, func, string } = PropTypes

EnterEmailSignup.propTypes = {
  form: object.isRequired,
  error: string.isRequired,
  onFormInvalid: func,
  onFormChange: func.isRequired,
  onCaptchaChange: func.isRequired,
  onFormSubmit: func,
  onLicenseRequest: func,
  isSending: PropTypes.bool
}

export default function EnterEmailSignup(props) {
  return(
    <div className='enter-email-signup'>
      <div className='login-action-title'>
        <h1>Get started!</h1>
        <h2>Create your account at Folloze.</h2>
      </div>
      <EnterEmail
        error={props.error}
        form={props.form}
        isSending={props.isSending}
        onFormInvalid={props.onFormInvalid}
        onFormChange={props.onFormChange}
        onFormSubmit={props.onFormSubmit}
        onCaptchaChange={props.onCaptchaChange}
        renderCaptcha={true}
        onLicenseRequest={props.onLicenseRequest}/>
      </div>
  )
}
