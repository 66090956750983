import React from 'react'
import PropTypes from 'prop-types'
import OtpRegistrationForm from './OtpRegistrationForm/OtpRegistrationForm'
import {  LoginErrorMessage } from 'Login/components'
const { object, string, func } = PropTypes

import './styles.css.scss'

OtpRegistration.propTypes = {
  form: object.isRequired,
  error: string.isRequired,
  onFormChange: func.isRequired
}

export default function OtpRegistration(props) {
  const {
    error,
    form,
    onFormChange,
    onFormSubmit
  } = props
  return(
    <div className='otp-registration'>
      <div className='login-action-title'>
        <h1>Register to Folloze Two Factor Authentication</h1>
      </div>
      <OtpRegistrationForm
        {...form}
        onChange={onFormChange}
        onSubmit={onFormSubmit}>
        <LoginErrorMessage error={error} />
      </OtpRegistrationForm>
    </div>
  )
}
