import React from 'react'
import { routes } from 'Login/services/api'
import { Form } from 'shared/UI/Form'
import merge from 'lodash/merge'

import './styles.css.scss'

const inputs = {
  firstName: {
    key: "firstName",
    name: 'user[name]',
    label: 'First Name',
    autoFocus: false,
    type: Form.InputTypes.requiredString,
    errorMessage: "First name can't be blank",
    order: 1
  },
  lastName: {
    key: "lastName",
    name: 'user[last_name]',
    label: 'Last Name',
    type: Form.InputTypes.requiredString,
    errorMessage: "Last name can't be blank",
    order: 2
  },
  email: {
    key: "email",
    name: 'user[email]',
    type: Form.InputTypes.hidden,
    errorMessage: '',
    order: 3
  },
  token: {
    key: "token",
    name: 'token',
    type: Form.InputTypes.hidden,
    errorMessage: '',
    order: 4
  },
  timezone: {
    key: "timezone",
    name: 'timezone',
    type: Form.InputTypes.hidden,
    errorMessage: '',
    order: 5
  },
  password: {
    key: "password",
    name: 'user[password]',
    label: 'Password',
    type: Form.InputTypes.newPassword,
    errorMessage: "Complete password",
    order: 6
  },
  tos: {
    key: "tos",
    name: 'user[tos]',
    type: Form.InputTypes.hidden,
    errorMessage: '',
    order: 7
  }
}

export default function SignupForm(props) {
  return(
    <div className='signup-form'>
      <Form
        {...merge({}, props, { inputs: inputs })}
        action={routes.signup.url}
        method={routes.signup.method}
        submitText={'Create Account'} />
    </div>
  )
}
