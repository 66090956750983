import React from 'react'
import PropTypes from 'prop-types'

import { INPUT_STYLES } from 'shared/UI/Input'

import './style.css.scss'


const INPUT_MESSAGE_TYPES = {
  [INPUT_STYLES.error]: {
    class: 'flz-input-error',
    icon: 'icon-error'
  },
  [INPUT_STYLES.warning]: {
    class: 'flz-input-warning',
    icon: 'icon-warning'
  },
  [INPUT_STYLES.success]: {
    class: 'flz-input-success',
    icon: 'icon-success'
  },
  [INPUT_STYLES.info]: {
    class: 'flz-input-info',
    icon: ''
  }
}

export default function InputMessage(props) {
  const messageTypeObj = props.type ? INPUT_MESSAGE_TYPES[props.type] : null
  return (   
    <div className={`input-message ${messageTypeObj.class}`}>
      <span className={messageTypeObj.icon}></span>
      {props.text}
    </div>
  )
}

InputMessage.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf([...Object.keys(INPUT_STYLES).map((k) => INPUT_STYLES[k])]),
}