import { axios } from 'shared/services/axios'

export function updateUsersStatus(userIds, state) {
  return axios.put('/api/v1/team_users/state', {
    user_ids: userIds,
    state
  })
}

export function updateOrganizationUsersTeam(params = {}) {
  return axios.put('/api/v1/team_users', params)
}

export function resendInvitation(email, captcha) {
  return axios.post('/api/v1/team_users/resend_invitation', {
    email,
    captcha
  })
}

export function inviteUser(teamId, token, params) {
  const userData = {
    captcha: token,
    ...params,
    name: params.first_name
  }

  return axios.post(`/api/v1/teams/${teamId}/users`, userData)
}

export function bulkInviteUsers(params) {
  return axios.post('/api/v1/users/bulk_invite', params)
}
