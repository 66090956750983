import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './styles.css.scss'

export default function NavigationLink(props) {
  const {navigationText, navigationLink, navigationLinkText, onLinkClick} = props

  const onClick = (e) => {
    if(!!onLinkClick){
      e.preventDefault()
      onClick()
    }
  }

  return(
    <div className='navigation-link'>
      <div className='text'>
        {navigationText}
      </div>
      <Link onClick={onLinkClick} className='link' to={navigationLink}>
        {navigationLinkText}
      </Link>
    </div>
  )
}

NavigationLink.propTypes = {
  navigationText: PropTypes.string.isRequired,
  navigationLink: PropTypes.string.isRequired,
  navigationLinkText: PropTypes.string.isRequired,
  onLinkClick : PropTypes.func
}