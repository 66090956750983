import React from 'react'
import PropTypes from 'prop-types'
import { ERRORS } from 'Login/helpers/errors'
import { NavigationLink } from 'Login/components'
import RequestLicenseButton from '../RequestLicenseButton/RequestLicenseButton'

import './styles.css.scss'

LoginErrorMessage.propTypes = {
  error: PropTypes.string.isRequired
}

function getMessage(props) {
  const { forgotPassword, resetPassword, signin, enterEmailSignup, signup, otpRegistration } = ERRORS

  switch (props.error) {
    case signin.invalid:
      return <p>Invalid email or password, please try again.</p>
    case signin.not_found_in_database:
      return <p>Invalid email or password, please try again.</p>
    case signin.invalid_otp:
      return <p>Wrong OTP, please try again.</p>
    case signin.inactive:
      return <p>This user account is currently not active. Please contact your company administrator or <a href="mailto:support@folloze.com">support@folloze.com</a></p>
    case signin.timeout:
      return <p> Session timeout, please login </p>
    case signin.locked:
      return <p>Too many login attempts, try again in 1 hour.</p>
    case signin.unconfirmed:
      return (
        <NavigationLink
          navigationText="An activation email has been sent to this account. "
          navigationLink=""
          navigationLinkText="Send again"
          onLinkClick={props.onResendConfirmation}/>
      )
    case enterEmailSignup.account_exists:
    case signup.account_exists:
      return <p>Invalid email</p>
    case enterEmailSignup.not_work_email:
    case signup.not_work_email:
      return <p>Please use work email.</p>
    case enterEmailSignup.closed_for_registration:
      return (
        <>
          <p>Thanks for signing up for Folloze, but your organization requires you be invited first.<br/>Please contact your admin, then hurry back!</p>
          <RequestLicenseButton onClick={props.onLicenseRequest} />
        </>
      )
    case enterEmailSignup.license_quota_exceeded:
      return (
        <>
          <p>Thanks for signing up for Folloze, but your organization has used all its Folloze platform licenses.<br/>Please contact your admin, then hurry back!</p>
          <RequestLicenseButton onClick={props.onLicenseRequest} />
        </>
      )
    case enterEmailSignup.org_email_not_exist:
      return <p>Your organization is not registered to our service</p>
    case enterEmailSignup.invalid_captcha:
    case signin.invalid_captcha:
      return <p>Invalid CAPTCHA.</p>
    case signup.invalid_token:
    case signin.invalid_token:
      return <p>This link has expired.</p>
    case signup.revoked_token:
      return <p>This invitation is invalid, please refer to your admin or contact support@folloze.com.</p>
    case signup.password_crackable:
      return <p> Passwords must be at least 8 characters, contain uppercase and lowercase letters, numbers and symbols (e.g. $, %) </p>
    case resetPassword.incorrect:
      return <p> Incorrect password </p>
    case resetPassword.passwords_must_match:
      return <p> The passwords must match each other </p>
    case resetPassword.password_already_used:
      return <p> This password has already been used </p>
    case resetPassword.token_expired:
      return <p> This link has expired </p>
    case resetPassword.password_expired:
      return <p> Your password has expired </p>
    case resetPassword.password_crackable:
      return <p> Passwords must be at least 8 characters, contain uppercase and lowercase letters, numbers and symbols (e.g. $, %) </p>
    case otpRegistration.invalid_details:
      return <p> Invalid details, please check your country code and phone number </p>
    case forgotPassword.email_not_exist:
      return (
        <NavigationLink
          navigationText="The email you entered does not match a folloze account. Please try again or "
          navigationLink="/signup"
          navigationLinkText="Sign Up"/>
      )
    default:
      return <p>missing error code ({props.error})</p>
  }
}

export default function LoginErrorMessage(props) {
  return (
    <div className='login-error-message'>
      <div className='message error'>
        {getMessage(props)}
      </div>
    </div>
  )
}
