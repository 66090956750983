import React from 'react'

import './styles.css.scss'

export default function LoginSuccessPage(props) {
  return (
    <div className='login-success-page'>
       <div className='login-action-title'>
        <h1>Thank You!</h1>
      </div>
      <div className={props.isSending ? 'spinner' : ''}></div>
      {props.children}
    </div>
  )
}
