import React from 'react'
import PropTypes from 'prop-types'
import ResetPasswordForm from './ResetPasswordForm/ResetPasswordForm'
import { LoginErrorMessage , PasswordValidator } from 'Login/components'

import './styles.css.scss'

const { object, string, func } = PropTypes

export default function ResetPassword(props) {
  const {
    onFormSubmit, error, form, children, onFormChange
  } = props
  return (
    <div className='reset-password'>
      <div className='login-action-title'>
        <h1>Reset Password</h1>
      </div>
      <ResetPasswordForm
        {...form}
        onChange={onFormChange}>
        <PasswordValidator value={form.inputs.newPassword.value} />
      </ResetPasswordForm>
      {error ? <LoginErrorMessage error={error}/> : ''}
    </div>
  )
}

ResetPassword.propTypes = {
  form: object.isRequired,
  error: string.isRequired,
  onFormChange: func.isRequired,
}