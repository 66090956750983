import trim from 'lodash/trim'
import pull from 'lodash/pull'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const WHITESPACE_AND_COMMAS_REGEX = /^,\s*|,\s*$/g
const SPACES_REGEX = /\s+/g

export function validateEmail(str) {
  return EMAIL_REGEX.test(str)
}

export function toObject(array) {
  let object = {}

  array.forEach(([key, value]) => object[key] = value)

  return object
}

function toCase(obj, caseTransformer) {
  let newObj = {}

  Object.keys(obj).forEach((key) => {
    newObj[caseTransformer(key)] = obj[key]
  })

  return newObj
}

export function toCamelCase(obj) {
  return toCase(obj, camelCase)
}

export function toSnakeCase(obj) {
  return toCase(obj, snakeCase)
}

export function cloneArray(arr) {
  return arr.slice(0)
}

export function mergeArraysWithoutDuplicates(arr1, arr2) {
  let result = cloneArray(arr1)

  arr2.map(item => {
    if(result.indexOf(item) == -1) {
      result.push(item)
    }
  })

  return result
}

export function mergeArraysWithoutDuplicatesBy(arr1, arr2, predicate) {
  let result = cloneArray(arr1)

  arr2.map(item => {
    if(predicate(result, item)) {
      result.push(item)
    }
  })

  return result
}

export function trimObject(object) {
  let trimedObject = {}

  Object.keys(object).forEach(key => {
    trimedObject[key] = trim(object[key])
  })

  return trimedObject
}

export function toggleArrayValue(arr, value) {
  const index = arr.indexOf(value)
  if(index != -1) {
    pull(arr, value)
  }
  else {
    arr.push(value)
  }
}

export function toggleArrayValueByPredicate(arr, value, predicate) {
  const itemToToggle = arr.find(predicate)
  if(itemToToggle) {
    pull(arr, itemToToggle)
  }
  else {
    arr.push(value)
  }
}

export function trimSpacesAndCommas(text) {
  return text.replace(WHITESPACE_AND_COMMAS_REGEX, '')
}

export function spacesToDashes(str) {
  return str.replace(SPACES_REGEX, '-')
}

export function getCookie(cname) {
  const reg = new RegExp(`(?:(?:^|.*;\\s*)${cname}\\s*\\=\\s*([^;]*).*$)|^.*$`)
  const cvalue = decodeURIComponent(document.cookie.replace(reg, "$1"))
  try {
    return JSON.parse(cvalue)
  }
  catch(e) {
    return cvalue
  }
}

export function setCookie(cname, cvalue) {
  if(typeof(cvalue) == "object") {
    cvalue = JSON.stringify(cvalue)
  }
  document.cookie = `${encodeURIComponent(cname)}=${encodeURIComponent(cvalue)};path=/`
}

export function deleteAllCookies() {
  document.cookie.split(";")
    .forEach(cookie => {
      if (cookie != '') {
        document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`)
      }
    })
}

export function expireCookie(cookieName) {
  document.cookie = `${cookieName}=;expires=${new Date().toUTCString()};path=/`
}

export function convertHexToRgbA(hex, alpha){
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        let c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+ alpha +')';
    }
}

export function stringOrEmpty(string) {
  return string || ""
}

export function removeWhiteSpaces(str) {
  return str.replace(/\s/g, '')
}

export function constructIdAndName(value) {
  return { id: value, name: value }
}