import { validateEmail } from 'shared/helper'
import passwordValidator from './passwordValidator'

const MIN_LENGTH = 8

export function email(target) {
  return validateEmail(target.value)
}

export function required(target) {
  return !!target.value
}

export function minLength(target) {
  return target.value.length >= MIN_LENGTH
}

export function checked(target) {
  return target.checked
}

export function optional(target) {
  return true
}

export function password(target) {
  return passwordValidator.isValid(target.value)
}

