import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'shared/hocs'
import EnterEmailSignup from './EnterEmailSignup'
import * as enterEmailActions from 'Login/modules/enterEmailSignup'
import { NavigationLink } from 'Login/components'

class EnterEmailSignupContainer extends Component {

  constructor(props) {
    super(props)

    this.state = {
      captcha: '',
      isCaptchaValid: false
    }
  }

  componentDidMount() {
    this.props.provideHeaderActions && this.props.provideHeaderActions(NavigationLink)
  }

  componentWillUnmount() {
    this.props.provideHeaderActions && this.props.provideHeaderActions(null)
    this.props.initializeSignup()
  }

  componentDidUpdate(prevProps) {
    if (this.props.isSso == null) {
      // when isSso in null it means that the signup flow in not decided yet - waiting for response from the server.
      return
    } else if (this.props.isSso) {
      this.runSsoFlow()
    }
    else {
      this.runSignupFlow()
    }
  }

  runSsoFlow = () => {
    window.location.href = this.props.ssoProvider.auth_url
  }

  runSignupFlow = () => {
    const {
      email, registrationToken, tokenCreationInProcess, createRegistrationToken, isPendingToken
    } = this.props

    if (isPendingToken) {
      this.props.navigate('/signup/resend')
    }
    else if(!registrationToken && !tokenCreationInProcess) {
      // create new token if there isnt  active registration process
      createRegistrationToken(email, this.state.captcha)
    }
    else if(registrationToken) {
      this.props.navigate(`/signup/${registrationToken}`)
    }
  }

  handleFormChange = (inputData) => {
    this.props.updateInputAndValidateForm(inputData)
  }

  handleFormSubmit = (data) => {
    const { isCaptchaValid } = this.state

    if (!isCaptchaValid) {
      return
    }

    this.props.handleSignupFlow(data.email)
  }

  handleCaptchaChange = (response) => {
    this.setState({ captcha: response, isCaptchaValid: !!response })
  }

  handleLicenseRequest = () => {
    const email = this.props.form?.inputs?.email?.value
    const errorCode = this.props.error.replace('enterEmailSignup_', '')
    this.props.sendLicenseRequest(email, errorCode)
  }

  render() {
    return (
      <EnterEmailSignup
        error={this.props.error}
        form={this.props.form}
        isSending={this.props.isSending}
        onFormChange={this.handleFormChange}
        onFormSubmit={this.handleFormSubmit}
        onCaptchaChange={this.handleCaptchaChange}
        onLicenseRequest={this.handleLicenseRequest}/>
    )
  }
}

function mapStateToProps ({ enterEmailSignup }, props) {
  return enterEmailSignup
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(enterEmailActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EnterEmailSignupContainer))
