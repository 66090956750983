import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'shared/hocs'
import { queryToParamsObject } from 'shared/helpers/UrlHelper'
import Signup from './Signup'
import { mapToAppError } from 'Login/helpers/errors'
import * as signupActions from 'Login/modules/signup'
import { NavigationLink } from 'Login/components'

class SignupContainer extends Component {
  constructor(props) {
    super(props)

    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  componentDidMount() {
    this.props.provideHeaderActions && this.props.provideHeaderActions(NavigationLink)
    this.props.setAndFetchRegistrationToken(this.props.params.token)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isFetchingRegistrationToken) {
      return
    }

    if (!this.props.isValidToken) {
      this.props.navigate('/signup')
    }

    const searchParams = queryToParamsObject(this.props.location.search)
    const error = searchParams.error

    if (!!error && !!this.props.email && !prevProps.email) {
      this.props.signupError(mapToAppError('signup', error))
    }
  }

  componentWillUnmount() {
    this.props.provideHeaderActions && this.props.provideHeaderActions(null)
  }

  onNavigateBack = () => {
    this.props.navigate('/signup')
  }

  handleFormSubmit(data, targetForm) {
    this.props.submitSignup({...data, type: 'email'})
    .then(() => targetForm.submit())
  }

  handleFormChange(inputData) {
    this.props.updateInputAndValidateForm(inputData)
  }

  render() {
    return (
      <Signup
        {...this.props}
        onNavigateBack={this.onNavigateBack}
        onFormSubmit={this.handleFormSubmit}
        onFormChange={this.handleFormChange}/>
    );
  }
}

function mapStateToProps ({ signup }) {
  return signup
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(signupActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignupContainer))
