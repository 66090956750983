import * as api from 'Login/services/api'
import { REHYDRATE } from 'redux-persist/constants'
import { mapToAppError } from 'Login/helpers/errors'
import { constants as formActionTypes, form } from 'shared/modules/form'
import omit from 'lodash/omit'
import { FETCHING_SIGNIN_TOKEN_SUCCESS, FETCHING_SIGNIN_TOKEN_FAILURE } from 'Login/modules/signin'
import { SET_EMAIL as SET_EMAIL_FORGOT_PASSWORD } from 'Login/modules/forgotPassword'

const UPDATE_INPUT = 'enterEmailSignin/UPDATE_INPUT'
const VALIDATE_FORM = 'enterEmailSignin/VALIDATE_FORM'
const CREATE_TOKEN_ERROR = 'enterEmailSignin/CREATE_TOKEN_ERROR'
const CREATE_TOKEN_SUCCESS = 'enterEmailSignin/CREATE_TOKEN_SUCCESS'
const REQUEST_OTP_SUCCESS = 'enterEmailSignin/REQUEST_OTP_SUCCESS'
const INITIALIZE_STATE = 'enterEmailSignin/INITIALIZE_STATE'
export const HANDLE_SGININ_FLOW_SUCCESS = 'enterEmailSignin/HANDLE_SGININ_FLOW_SUCCESS'
const SET_EMAIL = 'enterEmailSignin/SET_EMAIL'
const SET_IS_SENDING = 'enterEmailSignin/SET_IS_SENDING'
export const LOGIN_DISABLED_ERROR = 15

// ACTIONS

function updateInput(args) {
  return {
    ...args,
    type: UPDATE_INPUT
  }
}

function validateForm(args) {
  return {
    ...args,
    type: VALIDATE_FORM
  }
}

export function setError(error) {
  return {
    type: CREATE_TOKEN_ERROR,
    error
  }
}

function setIsSending(isSending) {
  return {
    type: SET_IS_SENDING,
    isSending
  }
}

export function createTokenError(error) {
  return function (dispatch, getState) {
    const email = getState().enterEmailSignin.email
    dispatch(setError(error))
  }
}

export function persist({ email, otpRequired }) {
  return { email, otpRequired }
}

function createTokenSuccess(token) {
  return {
    type: CREATE_TOKEN_SUCCESS,
    signinToken: token
  }
}

function handleSigninFlowSuccess(signinFlowData) {
  return {
    ...signinFlowData,
    type: HANDLE_SGININ_FLOW_SUCCESS
  }
}

function requestOtpSuccess(otpData) {
  return {
    ...otpData,
    type: REQUEST_OTP_SUCCESS
  }
}

function setEmail(email) {
  return {
    type: SET_EMAIL,
    email
  }
}

export function initializeSignin() {
  return {
    type: INITIALIZE_STATE
  }
}

export function handleSigninFlow(email) {
  return function (dispatch) {
    dispatch(setEmail(email))
    dispatch(setIsSending(true))
    return api.handleLoginFlow(email)
      .then(({ data }) => {
        dispatch(handleSigninFlowSuccess(data))
      })
      .catch(({ response }) => {
        const appError = mapToAppError('enterEmailSignin', response.data.error)
        dispatch(setIsSending(false))
        dispatch(setError(appError))
      })
  }
}

export function requestOtp(email) {
  return function (dispatch) {
    return api.requestOtp(email)
      .then(({ data }) => {
        dispatch(requestOtpSuccess(data))
      })
      .catch(({ response }) => {
        // TODO
      })
  }
}

export function createSigninToken(email) {
  return function (dispatch) {
    return api.createSigninToken(email)
      .then(({ data }) => {
        dispatch(createTokenSuccess(data.token))
      })
      .catch(({ response }) => {
        const appError = mapToAppError('enterEmailSignin', response.data.error)
        dispatch(createTokenError(appError))
      })
  }
}

export function updateInputAndValidateForm({ fieldName, value, isValid }) {
  return function (dispatch) {
    dispatch(updateInput({ fieldName, isValid, value }))
    dispatch(validateForm({ fieldName, isValid }))
    if (!isValid) {
      dispatch(setIsSending(false))
    }
  }
}

// REDUCERS

const initialInputState = {
  isValid: false,
  value: '',
  touched: false
}

const initialInputsState = {
  email: initialInputState
}

const initialFormState = {
  isValid: false,
  inputs: initialInputsState
}

const initialState = {
  isSending: false,
  email: '',
  error: '',
  otpSent: null,
  signinToken: null,
  isSso: null,
  ssoProvider: null,
  form: initialFormState,
  otpRequired: null,
  otpRegistrationRequired: null,
  isLoginDisabled: null
}

export default function enterEmailSignin(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_STATE:
      return {
        ...state,
        ...omit(initialState, ['form', 'email', 'otpRequired'])
      }
    case SET_EMAIL_FORGOT_PASSWORD:
    case SET_EMAIL:
      return {
        ...state,
        email: action.email
      }
    case CREATE_TOKEN_SUCCESS:
      return {
        ...state,
        signinToken: action.signinToken
      }
    case REQUEST_OTP_SUCCESS:
      return {
        ...state,
        otpSent: action.sent
      }
    case HANDLE_SGININ_FLOW_SUCCESS:
      return {
        ...state,
        isSso: action.is_sso,
        ssoProvider: action.sso_provider,
        otpRequired: action.otp_required,
        otpRegistrationRequired: action.otp_registration_required,
        isLoginDisabled: action.signin_error === LOGIN_DISABLED_ERROR
      }
    case CREATE_TOKEN_ERROR:
      return {
        ...state,
        error: action.error
      }
    case FETCHING_SIGNIN_TOKEN_SUCCESS:
      return {
        ...state,
        signinToken: null
      }
    case FETCHING_SIGNIN_TOKEN_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case UPDATE_INPUT:
      return {
        ...state,
        form: form(state.form, action, formActionTypes.UPDATE_INPUT)
      }
    case SET_IS_SENDING:
      return {
        ...state,
        isSending: action.isSending
      }
    case REHYDRATE:
      return {
        ...state,
        email: action.payload.enterEmailSignin &&
          action.payload.enterEmailSignin.email,
        signinToken: action.payload.enterEmailSignin &&
          action.payload.enterEmailSignin.signinToken,
        otpRequired: action.payload.enterEmailSignin &&
          action.payload.enterEmailSignin.otpRequired
      }
    case VALIDATE_FORM:
      return {
        ...state,
        form: form(state.form, action, formActionTypes.VALIDATE_FORM)
      }
    default:
      return state
  }
}
