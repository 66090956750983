import React from 'react';
import PropTypes from 'prop-types'
import EnterEmail from './EnterEmail'

const { object, func, string } = PropTypes

EnterEmailSignin.propTypes = {
  form: object.isRequired,
  error: string.isRequired,
  onFormInvalid: func,
  onFormChange: func.isRequired,
  handleFormSubmit: func
}

export default function EnterEmailSignin(props) {
  const {
    error, form, onFormInvalid, onFormChange, onFormSubmit, isSending
  } = props

  return(
    <div className='enter-email-signin'>
      <div className='login-action-title'>
        <h1>Login to your account</h1>
        <h2>and start engaging your customers!</h2>
      </div>
      <EnterEmail
        error={error}
        form={form}
        onFormInvalid={onFormInvalid}
        onFormChange={onFormChange}
        onFormSubmit={onFormSubmit}
        renderCaptcha={false}
        isSending={isSending}/>
    </div>
  )
}
