import React from 'react'
import PropTypes from 'prop-types'

import './style.css.scss'

export default function InlineInputIcon(props) {
  const isActionIcon = () => {
    return (props.onIconClick || props.onIconMouseUp || props.onIconMouseDown)
  }

  return (   
    <span 
      onMouseDown={props.onIconMouseDown} 
      onMouseUp={props.onIconMouseUp} 
      onMouseOut={props.onIconMouseOut}
      onClick={props.onIconClick} 
      className={`input-icon ${props.icon} ${isActionIcon() ? 'icon-action' : ''}`}
    />
  )
}

InlineInputIcon.propTypes = {
  icon: PropTypes.string,
  onIconMouseDown: PropTypes.func,
  onIconMouseUp: PropTypes.func,
  onMouseOut: PropTypes.func,
  onIconClick: PropTypes.func
}

