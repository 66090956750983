import React from 'react'

export const KEY_HANDLERS = {
  tab: 9,
  enter: 13,
  esc: 27,
  up: 38,
  down: 40
}

export default function withKeyEventHandler(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props)

      this.handlers = {}
      this.subscriber = document
    }

    componentDidMount() {
      this.subscriber.addEventListener('keydown', this.handleKeyEvent)
    }

    componentWillUnmount() {
      this.subscriber.removeEventListener('keydown', this.handleKeyEvent)
    }

    handleKeyEvent = (e) => {
      if(this.handlers && this.handlers[e.keyCode]) {
        e.stopPropagation()
        this.handlers[e.keyCode]()
      }
    }

    provideKeyHandlers = (handlers) => this.handlers = handlers
    provideSubscriber = (subscriber) => this.subscriber = subscriber

    render() {
      return (
        <WrappedComponent
          {...this.props}
          provideSubscriber={this.provideSubscriber}
          provideKeyHandlers={this.provideKeyHandlers} />
      )
    }
  }
}