import React from 'react'
import { getCsrfToken } from 'shared/helpers/CsrfTokenHelper'

export default function AuthenticityTokenInput(props) {
  const csrfToken = getCsrfToken()

  return (
    <input type='hidden' name='authenticity_token' value={csrfToken} />
  )
}
