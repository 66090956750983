import * as api from 'Login/services/api'
import { ERRORS, mapToAppError } from 'Login/helpers/errors'
import {
  FETCHING_REGISTRATION_TOKEN_SUCCESS, FETCHING_REGISTRATION_TOKEN_FAILURE
} from 'Login/modules/signup'
import { constants as formActionTypes, form } from 'shared/modules/form'

const UPDATE_INPUT = 'enterEmailSignup/UPDATE_INPUT'
const VALIDATE_FORM = 'enterEmailSignup/VALIDATE_FORM'
const CREATE_TOKEN_START = 'enterEmailSignup/CREATE_TOKEN_START'
const CREATE_TOKEN_FAILURE = 'enterEmailSignup/CREATE_TOKEN_FAILURE'
const CREATE_TOKEN_SUCCESS = 'enterEmailSignup/CREATE_TOKEN_SUCCESS'
const SET_EMAIL = 'enterEmailSignup/SET_EMAIL'
const PENDING_INVITATION = 'enterEmailSignup/PENDING_INVITATION'
const SIGNUP_FLOW_START = 'enterEmailSignup/SIGNUP_FLOW_START'
const SIGNUP_FLOW_SUCCESS = 'enterEmailSignup/SIGNUP_FLOW_SUCCESS'
const SIGNUP_FLOW_FAILURE = 'enterEmailSignup/SIGNUP_FLOW_FAILURE'
const SET_IS_SENDING = 'enterEmailSignin/SET_IS_SENDING'
const INITIALIZE_STATE = 'enterEmailSignin/INITIALIZE_STATE'

// ACTIONS

function updateInput(args) {
  return {
    ...args,
    type: UPDATE_INPUT
  }
}

function setIsSending(isSending) {
  return {
    type: SET_IS_SENDING,
    isSending: isSending
  }
}

function validateForm(args) {
  return {
    ...args,
    type: VALIDATE_FORM
  }
}

export function createTokenFailure(error) {
  return function (dispatch, getState) {
    const email = getState().enterEmailSignup.email

    dispatch({
      type: CREATE_TOKEN_FAILURE,
      error
    })
  }
}

function createTokenSuccess(token) {
  return {
    type: CREATE_TOKEN_SUCCESS,
    registrationToken: token
  }
}

function setEmail(email) {
  return {
    type: SET_EMAIL,
    email
  }
}

export function handleSignupFlow(email){
  return function (dispatch) {
    dispatch({
      type: SIGNUP_FLOW_START,
      email
    })
    return api.handleLoginFlow(email)
      .then(({data}) => {
        dispatch({
          type: SIGNUP_FLOW_SUCCESS,
          ...data
        })
      })
      .catch(({response}) => {
        const appError = mapToAppError('enterEmailSignup', response.data.error)
        dispatch({
          type: SIGNUP_FLOW_FAILURE,
          error: appError
        })
      })
  }
}

export function createRegistrationToken(email, captcha) {
  return function (dispatch) {
    dispatch({
      type: CREATE_TOKEN_START,
    })
    return api.createRegistrationToken(email, captcha)
      .then(({ data }) => {
        dispatch({
          type: CREATE_TOKEN_SUCCESS,
          registrationToken: data.token
        })
      })
      .catch(({ response }) => {
        const appError = mapToAppError('enterEmailSignup', response.data.error)
        const isPending = appError === ERRORS.enterEmailSignup.pending_token

        if(isPending) {
          dispatch({
            type: PENDING_INVITATION,
            email: email
          })
        }
        else {
          dispatch(createTokenFailure(mapToAppError('enterEmailSignup', response.data.error)))
        }
      })
  }
}

export function updateInputAndValidateForm({ fieldName, value, isValid }) {
  return function (dispatch) {
    dispatch(updateInput({ fieldName, isValid, value }))
    dispatch(validateForm({ fieldName, isValid }))
    if(!isValid){
      dispatch(setIsSending(false))
    }
  }
}

export function sendLicenseRequest(email, errorCode) {
  return function (dispatch) {
    return api.sendLicenseRequest(email, errorCode)
  }
}

export function initializeSignup() {
  return {
    type: INITIALIZE_STATE
  }
}

// REDUCERS

const initialInputState = {
  isValid: false,
  value: '',
  touched: false
}

const initialInputsState = {
  email: initialInputState
}

const initialFormState = {
  isValid: false,
  inputs: initialInputsState
}

const initialState = {
  isSending: false,
  error: '',
  registrationToken: null,
  isSso: null,
  ssoProvider: null,
  closedForRegistration: false,
  form: initialFormState,
  isPendingToken: false,
  tokenCreationInProcess:false
}

export default function enterEmailSignup(state = initialState, action) {
  switch(action.type) {
    case INITIALIZE_STATE: {
      return initialState
    }
    case CREATE_TOKEN_START:
      return {
        ...state,
        tokenCreationInProcess:true
      }
    case CREATE_TOKEN_SUCCESS:
      return {
        ...state,
        registrationToken: action.registrationToken,
        isSending: false,
        tokenCreationInProcess:false
      }
    case CREATE_TOKEN_FAILURE:
      return {
        ...initialState,
        form: state.form,
        error: action.error,
        isSending: false
      }
    case PENDING_INVITATION:
      return {
        ...state,
        isPendingToken: true,
        isSending: false,
        tokenCreationInProcess:false
      }
    case SIGNUP_FLOW_START:
      return {
        ...state,
        error:'',
        isSending: true,
        email: action.email
      }
    case SIGNUP_FLOW_SUCCESS:
      return {
        ...state,
        isSso: action.is_sso,
        ssoProvider: action.sso_provider
      }
    case SIGNUP_FLOW_FAILURE:
      return {
        ...initialState,
        form: state.form,
        error: action.error,
        isSending: false
      }
    case UPDATE_INPUT:
      return {
        ...state,
        form: form(state.form, action, formActionTypes.UPDATE_INPUT)
      }
    case VALIDATE_FORM:
      return {
        ...state,
        form: form(state.form, action, formActionTypes.VALIDATE_FORM)
      }
    case SET_EMAIL:
      return {
        ...state,
        email: action.email
      }
    case SET_IS_SENDING:
      return {
        ...state,
        isSending: action.isSending
      }
    case FETCHING_REGISTRATION_TOKEN_SUCCESS:
      return {
        ...state,
        registrationToken: null
      }
    case FETCHING_REGISTRATION_TOKEN_FAILURE:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}
