import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'shared/hocs'
import { connect } from 'react-redux'
import { sendLicenseRequest } from 'Login/services/api'
import RequestLicenseButton from '../RequestLicenseButton/RequestLicenseButton'
import { LOGIN_DISABLED_ERROR } from 'Login/modules/enterEmailSignin'

function SigninUnauthorized(props) {
  const handleLicenseRequest = () => {
    sendLicenseRequest(props.email, LOGIN_DISABLED_ERROR)
  }

  if (!props.email) {
    props.navigate('/signin')
  }

  return (
    <div className='signin-unauthorized'>
      <div className='login-action-title'>
        <h1>Your current license doesn’t allow logging into the system</h1>
        <h2>Want to upgrade? Request your company administrator for a license with login rights and enjoy the variety of Folloze capabilities</h2>
        <RequestLicenseButton onClick={handleLicenseRequest} />
      </div>
    </div>
  )
}

function mapStateToProps({ enterEmailSignin }) {
  return {
    email: enterEmailSignin.email
  }
}

SigninUnauthorized.propTypes = {
  email: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired
}

export default connect(mapStateToProps, null)(withRouter(SigninUnauthorized))
