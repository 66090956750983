import React from 'react'
import { routes } from 'Login/services/api'
import { Form } from 'shared/UI/Form'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import { ForgotPasswordLink } from 'Login/components'

import './styles.css.scss'

const inputs = {
  password: {
    key: 'password',
    label: 'Password',
    placeholder: '',
    autoFocus: true,
    type: Form.InputTypes.password,
    errorMessage: "Password can't be blank",
    name: 'user[password]',
    order: 1,
    renderBefore: () => {
      return (<ForgotPasswordLink/>)
    }
  },
  token: {
    key: 'token',
    type: Form.InputTypes.hidden,
    errorMessage: '',
    name: 'token',
    order: 2
  },
  email: {
    key: 'email',
    type: Form.InputTypes.hidden,
    errorMessage: 'Please check the email format',
    name: 'user[email]',
    order: 3
  },
  otp: {
    key: 'otp',
    placeholder: '',
    label: 'Enter your code',
    type: Form.InputTypes.hidden,
    errorMessage: "Otp can't be blank",
    name: 'user[otp]',
    className: 'otp-token',
    order: 4
  },
  resendOtp: {
    key: 'resendOtp',
    type: Form.InputTypes.hidden,
    errorMessage: '',
    name: 'resendOtp',
    value: 'Resend Code',
    className: 'resend-otp',
    order: 5
  },
  captcha: {
    key: 'captcha',
    type: Form.InputTypes.hidden,
    errorMessage: '',
    name: 'user[captcha]',
    order: 6
  }
}

export default function SigninForm(props) {
  const formInputs = cloneDeep(inputs)
  if (props.otpRequired) {
    formInputs.otp.type = Form.InputTypes.requiredNumber
    formInputs.resendOtp.type = Form.InputTypes.button
    formInputs.resendOtp.onClick = props.onResendOtp
  }

  return (
    <div className='signin-form'>
      <Form
        {...merge({}, props, { inputs: formInputs })}
        action={routes.signin.url}
        method={routes.signin.method}
        submitText={'Login to your account'}>
        {props.children}
      </Form>
    </div>
  )
}
