import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useNavigate } from 'react-router-dom'
import ResendInvitation from './ResendInvitation'
import * as resendInvitationActions from 'Login/modules/resendInvitation'

const ResendInvitationContainer = (props) => {
  const navigate = useNavigate()
  const [captchaResponse, setCaptchaResponse] = useState()

  useEffect(() => {
    if (!!props.resendInvitationSucceded) {
      navigate('/signup/resend/success', { replace: true })
      return
   }
  }, [props.resendInvitationSucceded])

  const handleOnClick = () => {
    if(!!captchaResponse){
      props.resend(props.email, captchaResponse)
    }
    return
  }

  return (
    <ResendInvitation
      onClick={handleOnClick}
      onCaptchaChange={setCaptchaResponse}
      email={props.email}
    />
  )
}

function mapStateToProps ({ enterEmail, resendInvitation }) {
  return {
    email: enterEmail.email,
    resendInvitationSucceded: resendInvitation.resendInvitationSucceded,
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(resendInvitationActions, dispatch)
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResendInvitationContainer)
