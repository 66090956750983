import { REHYDRATE } from 'redux-persist/constants'
import { ACCOUNT_REQUESTED, SIGNUP_ERROR } from 'Login/modules/signup'
import * as api from 'Login/services/api'
import get from 'lodash/get'

const SENDING_CONFIRMATION = 'accountCreated/SENDING_CONFIRMATION'
const SENDING_CONFIRMATION_SUCCESS = 'accountCreated/SENDING_CONFIRMATION_SUCCESS'
const SENDING_CONFIRMATION_FAILURE = 'accountCreated/SENDING_CONFIRMATION_FAILURE'

function sendingConfirmation() {
  return {
    type: SENDING_CONFIRMATION
  }
}

function sendingConfirmationSuccess() {
  return {
    type: SENDING_CONFIRMATION_SUCCESS
  }
}

function sendingConfirmationFailure(error) {
  return {
    type: SENDING_CONFIRMATION_FAILURE,
    error: error
  }
}

export function resendConfirmation(email, captcha) {
  return function (dispatch) {
    dispatch(sendingConfirmation())
    return api.resendConfirmation(email, captcha)
      .then(({ data }) => {
        dispatch(sendingConfirmationSuccess())
      })
      .catch(({ error }) => {
        dispatch(sendingConfirmationFailure(error))
      })
  }
}

export function persist({ email }) {
  return { email }
}

const initialState = {
  email: null,
  error: '',
  isSending: false,
}

export default function accountCreated(state = initialState, action) {
  switch(action.type) {
    case ACCOUNT_REQUESTED:
      return {
        ...state,
        email: action.email
      }
    case SIGNUP_ERROR:
      return {
        ...state,
        email: null
      }
    case SENDING_CONFIRMATION:
      return {
        ...state,
        isSending: true,
      }
    case SENDING_CONFIRMATION_SUCCESS:
      return {
        ...state,
        isSending: false,
      }
    case SENDING_CONFIRMATION_FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.error,
      }
    case REHYDRATE:
      const email = get(action.payload, ['accountCreated', 'email'])

      if (!!email) {
        return {
          ...state,
          email: email
        }
      }
      else {
        return {
          ...state,
          error: 'No email'
        }
      }
    default:
      return state
  }
}