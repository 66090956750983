import { constants as formActionTypes, form } from 'shared/modules/form'

const UPDATE_INPUT = 'forgotPassword/UPDATE_INPUT'
const VALIDATE_FORM = 'forgotPassword/VALIDATE_FORM'
const SET_ERROR = 'forgotPassword/SET_ERROR'
export const SET_EMAIL = 'forgotPassword/SET_EMAIL'

// ACTIONS

function updateInput(args) {
  return {
    ...args,
    type: UPDATE_INPUT
  }
}

function validateForm(args) {
  return {
    ...args,
    type: VALIDATE_FORM
  }
}

function setEmail(email) {
  return {
    type: SET_EMAIL,
    email
  }
}

export function setError(error) {
  return {
    type: SET_ERROR,
    error
  }
}

export function updateInputAndValidateForm({ fieldName, value, isValid }) {
  return function (dispatch) {
    dispatch(setEmail(value))
    dispatch(updateInput({ fieldName, isValid, value }))
    dispatch(validateForm({ fieldName, isValid }))
  }
}

export function submitForgotPassword({ email, type }) {
  return function (dispatch) {
    dispatch(setEmail(email))
  }
}

// REDUCERS

const initialInputState = {
  isValid: false,
  value: '',
  touched: false
}

const initialInputsState = {
  email: initialInputState,
  captcha: initialInputState
}

const initialFormState = {
  isValid: false,
  inputs: initialInputsState
}

const initialState = {
  email: '',
  error: '',
  form: initialFormState
}

export default function forgotPassword(state = initialState, action) {
  switch (action.type) {
    case SET_EMAIL:
      return {
        ...state,
        email: action.email
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.error
      }
    case UPDATE_INPUT:
      return {
        ...state,
        form: form(state.form, action, formActionTypes.UPDATE_INPUT)
      }
    case VALIDATE_FORM:
      return {
        ...state,
        form: form(state.form, action, formActionTypes.VALIDATE_FORM)
      }
    default:
      return state
  }
}
