import React from 'react'
import {Input, InputMessage, INPUT_STYLES } from 'shared/UI/Input'
import PropTypes from 'prop-types'
import { INPUT_SIZES } from 'shared/UI/Input'

class InputForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { 
      isValid: false, 
      isAlertPresented: false 
    }
  }

  handleBlur = (e) => {
    if (this.props.validate) {
      this.setState({ 
        isAlertPresented: true
      })
    } 
  }

  handleChange = (e) => {
    if (this.props.validate) {
      const isValid = this.props.validate(e.target)
      this.props.onChange && this.props.onChange({
        value: e.target.value,
        isValid: isValid,
        fieldName: this.props.fieldName
      })
      // clear - during typing, don't show errors
      this.setState({ 
        isAlertPresented: false,
        isValid: isValid
      })
    }
  }

  shouldShowAlert() {
    // don't show error if didn't started to type
    return !this.state.isValid && this.state.isAlertPresented
  }

  renderAlertMessage = () => {
    return (
      <InputMessage
        text={this.props.alertText}
        type={INPUT_STYLES.error}
      />
    )
  }

  render() {
    return (
      <Input
        label={this.props.label}
        type={this.props.type}
        name={this.props.name}
        value={this.props.value}
        placeholder={this.props.placeholder}
        size={INPUT_SIZES.medium}
        className={this.shouldShowAlert() ? INPUT_STYLES.error : null}
        autoFocus={this.props.autoFocus}
        onClick={this.props.onClick}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        renderInlineIcon={this.props.renderInlineIcon}
        renderBefore={this.props.renderBefore}
        renderAfter={this.shouldShowAlert() ? this.renderAlertMessage : null}
      />
    )
  }
}

InputForm.propTypes = {
  validate: PropTypes.func,
}

export default InputForm