import React from 'react'
import { Form } from 'shared/UI/Form'
import merge from 'lodash/merge'
import { routes } from 'Login/services/api'

import './styles.css.scss'

const inputs = {
  newPassword: {
    key: 'newPassword',
    name: 'user[password]',
    placeholder: '',
    label: 'New Password',
    autoFocus: true,
    type: Form.InputTypes.newPassword,
    errorMessage: "Complete password",
    order: 1
  },
  confirmPassword: {
    key: 'confirmPassword',
    name: 'user[password_confirmation]',
    placeholder: '',
    label: 'Confirm Password',
    autoFocus: false,
    type: Form.InputTypes.password,
    errorMessage: "Password can't be blank",
    order: 2
  },
  token: {
    key: 'token',
    name: 'user[reset_password_token]',
    type: Form.InputTypes.hidden,
    errorMessage: '',
    order: 3
  }
}

export default function ResetPasswordForm (props) {
  return (
    <div className='reset-password-form'>
      <Form
        {...merge({}, props, { inputs: inputs })}
        action={routes.reset_password.url}
        method={routes.reset_password.method}
        submitText={'Change Password'}>
        <input type="hidden" name="_method" value="PUT"/>
        {props.children}
      </Form>
    </div>
  )
}
