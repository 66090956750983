import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useNavigate } from 'react-router-dom'
import AccountCreated from './AccountCreated'
import * as accountCreatedActions from 'Login/modules/accountCreated'
import ReCAPTCHA from 'react-google-recaptcha'
import { executeCaptchaAsync } from 'shared/helpers/RecaptchaHelper'

const AccountCreatedContainer = (props) => {
  const navigate = useNavigate()
  const recaptchaRef = useRef()

  useEffect(() => {
    if (!!props.error) {
      navigate('/signin', { replace: true })
    }
  },[props.error])

  const handleResend = () => {
    executeCaptchaAsync(recaptchaRef).then(token => {
      props.resendConfirmation(props.email, token)
    })
  }

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={FollozeState.envConfig.INVISIBLE_RECAPTCHA_SITE_KEY}/>
      <AccountCreated
        onResend={handleResend}
        isSending={props.isSending} />
    </>
  )
}

function mapStateToProps({ accountCreated }, props) {
  return accountCreated
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(accountCreatedActions, dispatch)
}

AccountCreatedContainer.propTypes = {
  email: PropTypes.string,
  error: PropTypes.string,
  isSending: PropTypes.bool,
  resendConfirmation: PropTypes.func
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountCreatedContainer)
