import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './styles.css.scss'

export default class ResendInvitationSuccessPage extends Component {
  render() {
    return(
      <div className='resend-invitation-success-page'>
        <div className='title'>{'Thank You!'}</div>
        <div className='envelope'></div>
        <div className='text-container'>
          <p className='text'>
            {'Please check your email,'}
              <br/>
            {'we have sent you the invitation.'}
          </p>
        </div>
        <p className='alert-message'>
          {"* If you don't see the invitation email, please check your junk/spam folder."}
        </p>
      </div>
    )
  }
}
