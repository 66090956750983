import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'shared/hocs'
import EnterEmailSignin from './EnterEmailSignin'
import * as enterEmailActions from 'Login/modules/enterEmailSignin'
import { NavigationLink } from 'Login/components'

class EnterEmailSigninContainer extends Component {
  componentDidMount() {
    this.props.provideHeaderActions && this.props.provideHeaderActions(NavigationLink)
  }

  componentWillUnmount() {
    this.props.provideHeaderActions && this.props.provideHeaderActions(null)
    this.props.initializeSignin()
  }

  componentDidUpdate() {
    if (this.props.isSso != null) {
      if (this.props.isLoginDisabled) {
        this.props.navigate('/signin/unauthorized')
        return
      }
      this.props.isSso ? this.runSsoFlow() : this.runPasswordFlow()
    }
  }

  runSsoFlow = () => {
    window.location.href = this.props.ssoProvider.auth_url
  }

  runPasswordFlow = () => {
    if (!this.props.signinToken) {
      this.props.createSigninToken(this.props.email)
    } else {
      if (this.props.otpRequired) {
        this.createOneTimePassword()
        return
      }
      this.props.navigate(`/signin/${this.props.signinToken}`)
    }
  }

  createOneTimePassword = () => {
    if (this.props.otpRegistrationRequired) {
      this.props.navigate(`/otp/registration/${this.props.signinToken}`)
    } else {
      this.props.requestOtp(this.props.email)
      this.props.navigate(`/signin/${this.props.signinToken}`)
    }
  }

  handleFormChange = (inputData) => {
    this.props.updateInputAndValidateForm(inputData)
  }

  handleFormSubmit = (data) => {
    this.props.handleSigninFlow(data.email)
  }

  render() {
    return (
      <EnterEmailSignin
        error={this.props.error}
        form={this.props.form}
        isSending={this.props.isSending}
        onFormChange={this.handleFormChange}
        onFormSubmit={this.handleFormSubmit}/>
    )
  }
}

EnterEmailSigninContainer.propTypes = {
  error: PropTypes.string,
  form: PropTypes.object,
  isSending: PropTypes.bool,
  isSso: PropTypes.bool,
  ssoProvider: PropTypes.object,
  isLoginDisabled: PropTypes.bool,
  signinToken: PropTypes.string,
  createSigninToken: PropTypes.func,
  email: PropTypes.string,
  handleSigninFlow: PropTypes.func,
  initializeSignin: PropTypes.func,
  navigate: PropTypes.func,
  otpRequired: PropTypes.bool,
  otpRegistrationRequired: PropTypes.bool,
  provideHeaderActions: PropTypes.func,
  requestOtp: PropTypes.func,
  updateInputAndValidateForm: PropTypes.func
}

function mapStateToProps ({ enterEmailSignin }, props) {
  return enterEmailSignin
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(enterEmailActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EnterEmailSigninContainer))
