import merge from 'lodash/merge'

const UPDATE_INPUT = 'form/UPDATE_INPUT'
const VALIDATE_FORM = 'form/VALIDATE_FORM'
const SET_INPUTS = 'form/SET_INPUTS'
const REHYDRATE = 'form/form_REHYDRATE'

export const constants = { UPDATE_INPUT, VALIDATE_FORM, SET_INPUTS, REHYDRATE }

function input(state, action, form_action) {
  switch (form_action) {
    case UPDATE_INPUT:
      return {
        ...state,
        value: action.value,
        isValid: action.isValid,
        touched: true
      }
    default:
      return state
  }
}

function inputs(state, action, form_action) {
  switch (form_action) {
    case SET_INPUTS:
      const injected_inputs = {
        email: {
          value: action.inputs.email,
          isValid: true,
          touched: false
        },
        firstName: {
          value: action.inputs.first_name || '',
          isValid: !!action.inputs.first_name,
          touched: false
        },
        lastName: {
          value: action.inputs.last_name || '',
          isValid: !!action.inputs.last_name,
          touched: false
        }
      }

      return merge({}, state, injected_inputs)
    case UPDATE_INPUT:
      return {
        ...state,
        [action.fieldName]: input(state[action.fieldName], action, form_action)
      }
    default:
      return state
  }
}

export function form(state, action, form_action, options = {}) {
  switch (form_action) {
    case SET_INPUTS:
      return {
        ...state,
        inputs: inputs(state.inputs, action, form_action)
      }
    case UPDATE_INPUT:
      return {
        ...state,
        inputs: inputs(state.inputs, action, form_action)
      }
    case REHYDRATE:
      return merge({}, state, options['payload'])
    case VALIDATE_FORM:
      const isValid = Object.keys(state.inputs)
        .reduce((_isValid, fieldName) => {
            return _isValid && state.inputs[fieldName].isValid
          }, true)

      return {
        ...state,
        isValid
      }
    default:
      return state
  }
}