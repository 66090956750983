
import React, { Component } from 'react'
import { Button, BUTTON_SIZES, BUTTON_TYPES } from 'shared/UI/Button'
import PropTypes from 'prop-types'

import './styles.css.scss'

class RequestLicenseButton extends Component {
  constructor(props) {
    super(props)
    this.state = { clicked: false }
  }

  handleClick = () => {
    this.props.onClick()
    this.setState({ clicked: true })
  }

  render() {
    return (
      <Button
        onClick={this.handleClick}
        className='request-license-button'
        size={BUTTON_SIZES.small}
        type={BUTTON_TYPES.primary}
        disabled={this.state.clicked} >
        Ask for a License
      </Button>
    )
  }
}

RequestLicenseButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default RequestLicenseButton
