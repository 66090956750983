import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import SigninForm from './SigninForm/SigninForm'
import { LoginErrorMessage, NavigationLink, EmailAvatar } from 'Login/components'

import './styles.css.scss'

class Signin extends React.Component {
  render() {
    const {
      error, form, onFormChange, onFormSubmit, onResendConfirmation,
      onCaptchaChange, otpRequired, onResendOtp, email, onNavigationBack
    } = this.props

    return (
      <div className='signin'>
        <div className='login-action-title'>
          <h1>Login to your account</h1>
          <h2>and start engaging your customers!</h2>
        </div>
        <EmailAvatar email={email} onEditEmail={onNavigationBack}/>
        <SigninForm
          {...form}
          otpRequired={otpRequired}
          onChange={onFormChange}
          onSubmit={onFormSubmit}
          onResendOtp={onResendOtp}>
          <div className='captcha-container'>
            <ReCAPTCHA
              ref={ref => this.recaptcha = ref}
              sitekey={FollozeState.envConfig.RECAPTCHA_SITE_KEY}
              onChange={onCaptchaChange} />
          </div>
        </SigninForm>
        {error ? <LoginErrorMessage error={error} onResendConfirmation={onResendConfirmation}/> : ''}
      </div>
    )
  }
}

Signin.propTypes = {
  form: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  onFormInvalid: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onResendConfirmation: PropTypes.func.isRequired,
  onCaptchaChange: PropTypes.func.isRequired,
  otpRequired: PropTypes.bool,
  onResendOtp: PropTypes.func,
  onNavigationBack: PropTypes.func,
  email: PropTypes.string
}

export default Signin
