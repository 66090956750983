import { axios } from 'shared/services/axios'

export const routes = {
  signin: { url: '/signin', method: 'POST' },
  signup: { url: '/signup', method: 'POST' },
  forgot_password: { url: '/users/password', method: 'POST' },
  reset_password: { url: '/users/password', method: 'POST' }
}

export function createRegistrationToken(email, captcha) {
  return axios.post('/api/v1/registration_tokens', {
    email: email,
    captcha: captcha
  })
}

export function createSigninToken(email) {
  return axios.post('/api/v1/signin_tokens', {
    email: email
  })
}
export function createOtpRegistration(email, phoneNumber, countryCode) {
  return axios.post('/api/v1/otp/registrations', {
    email: email,
    phone_number: phoneNumber,
    country_code: countryCode
  })
}

export function requestOtp(email) {
  return axios.post('/api/v1/otp/requests', {
    email
  })
}

export function handleLoginFlow(email) {
  return axios.post('/api/v1/login_flows', {
    email: email
  })
}

export function fetchRegistrationToken(token) {
  return axios.get(`/api/v1/registration_tokens/${token}`)
}

export function fetchSigninToken(token) {
  return axios.get(`/api/v1/signin_tokens/${token}`)
}

export function resendConfirmation(email, captcha) {
  return axios.post('/users/confirmation', {
    user: { email },
    captcha
  })
}

export function sendLicenseRequest(email, errorCode) {
  return axios.post('/api/v1/license_requests', { email, error_code: errorCode })
}
