import InputForm from './InputForm'
import SecretInput from './SecretInput'

const FieldsFactory = () => {

  const getField = (fieldType) => {
    switch(fieldType) {
      case 'password':
        return SecretInput
      default:
        return InputForm
    }
  }
  return { getField }
}

export default FieldsFactory()
