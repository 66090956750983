const moment = require('moment-timezone')

function TimezoneService() {

    function zoneNames() {
       window.moment = moment
       return moment.tz.names()
    }

    function currentTimezone() {
       return moment.tz.guess()
    }

    var result =  {
        zoneNames: zoneNames,
        currentTimezone : currentTimezone
    };

    return result;
}

module.exports = TimezoneService;
