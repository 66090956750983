import React from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

const withRouter = (WrappedComponent) => {
  const Wrapper = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    return (
      <WrappedComponent
        navigate={navigate}
        location={location}
        params={params}
        {...props} />
    )
  }

  return Wrapper
}

export default withRouter