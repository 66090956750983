import React from 'react'
import { LoginSuccessPage, NavigationLink } from 'Login/components';
import { Button, BUTTON_SIZES, BUTTON_TYPES, BUTTON_STYLES } from 'shared/UI/Button'

export default function AccountCreated(props) {
  return (
    <LoginSuccessPage isSending={props.isSending}>
      <div className='account-created'>
        <div className='login-action-title'>
          <h2>{props.isSending ? 'Sending...' : 'An activation email has been sent to you.'}</h2>
        </div>
         <div className='button-container'>
          <Button
            size={BUTTON_SIZES.medium}
            type={BUTTON_TYPES.primary}
            style={[BUTTON_STYLES.lightText]}
            onClick={props.onResend}>
            Send activation message again
          </Button>
        </div>
      </div>
    </LoginSuccessPage>
  )
}
