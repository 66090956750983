import React from 'react'
import { Form } from 'shared/UI/Form'
import merge from 'lodash/merge'
import { routes } from 'Login/services/api'

import './styles.css.scss'

const inputs = {
  email: {
    key: 'email',
    placeholder: '',
    label: 'Enter Your Work Email',
    name: 'user[email]',
    autoFocus: true,
    type: Form.InputTypes.email,
    errorMessage: 'Please enter a valid email address',
    order: 1
  },
  captcha: {
    key: 'captcha',
    type: Form.InputTypes.hidden,
    errorMessage: '',
    name: 'user[captcha]',
    order: 2
  }
}

export default function ForgotPasswordForm (props) {
  return (
    <div className='forgot-password-form'>
      <Form
        {...merge({}, props, { inputs: inputs })}
        action={routes.forgot_password.url}
        method={routes.forgot_password.method}
        submitText={'Reset Password'}>
        {props.children}
      </Form>
    </div>
  )
}
