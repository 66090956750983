import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import './styles.css.scss'

export default function EmailAvatar(props) {
  return(
    <div className='email-avater'>
      <span className='icon icon-nav-avatar-user'/>
      <span className='text'>{props.email}</span>
      <span onClick={props.onEditEmail} className='text icon-edit'/>
    </div>
  )
}

EmailAvatar.propTypes = {
  email: PropTypes.string,
  onEditEmail: PropTypes.func
}