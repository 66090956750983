import * as api from 'App/api/teamUsers'

const RESEND_INVITATION_START = 'resendInvitation/RESEND_INVITATION_START'
const RESEND_INVITATION_SUCCESS = 'resendInvitation/RESEND_INVITATION_SUCCESS'
const RESEND_INVITATION_FAILURE = 'resendInvitation/RESEND_INVITATION_FAILURE'

// ACTIONS

function resendingInvitation(args) {
  return {
    ...args,
    type: RESEND_INVITATION_START
  }
}

function resendInvitationSuccess(args) {
  return {
    ...args,
    type: RESEND_INVITATION_SUCCESS
  }
}

function resendInvitationFailure() {
  return {
    type: RESEND_INVITATION_FAILURE
  }
}

export function resend(email, captcha) {
  return function (dispatch) {
    dispatch(resendingInvitation(email))
    return api.resendInvitation(email, captcha)
      .then(({ data }) => {
        dispatch(resendInvitationSuccess())
      })
      .catch(({ error }) => {
        dispatch(resendInvitationFailure(error))
      })
  }
}


// REDUCERS

const initialState = {
  error: '',
  resendInvitationSucceded: false,
  resendingInvitation: false
}

export default function resendInvitation(state = initialState, action) {
  switch(action.type) {
    case RESEND_INVITATION_START:
      return {
        ...state,
        resendingInvitation: true
      }
    case RESEND_INVITATION_SUCCESS:
      return {
        ...state,
        resendInvitationSucceded: true,
        resendingInvitation: false
      }
    case RESEND_INVITATION_FAILURE:
      return {
        ...state,
        error: action.error,
        resendInvitationSucceded: false,
        resendingInvitation: false
      }
    default:
      return state
  }
}
