import React from 'react'
import PropTypes from 'prop-types'
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom'
import {
  LoginApp, ForgotPasswordSuccessPage, ResendInvitationSuccessPage,
  SigninContainer, SignupContainer, ResetPasswordContainer, SigninUnauthorized,
  ForgotPasswordContainer, AccountCreatedContainer, ResendInvitationContainer,
  EnterEmailSigninContainer, EnterEmailSignupContainer, OtpRegistrationContainer
} from 'Login/components'

export default function getRoutes() {
  // Login wrapper was created to enable passing props to children routes
  const LoginAppWrapper = (props) => {
    const Component = props.component

    return (
      <LoginApp>
        <Component />
      </LoginApp>
    )
  }

  LoginAppWrapper.propTypes = {
    component: PropTypes.func.isRequired
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/">
          <Navigate to="signin" />
          <Route path="signin">
            <Route element={<LoginAppWrapper component={EnterEmailSigninContainer} />} />
            <Route path=":token" element={<LoginAppWrapper component={SigninContainer} />}/>
            <Route path="unauthorized" element={<LoginAppWrapper component={SigninUnauthorized} />}/>
          </Route>
          <Route path="signup">
            <Route element={<LoginAppWrapper component={EnterEmailSignupContainer} />} />
            <Route path="success" element={<LoginAppWrapper component={AccountCreatedContainer} />} />
            <Route path="resend">
              <Route element={<LoginAppWrapper component={ResendInvitationContainer} />} />
              <Route path="success" element={<LoginAppWrapper component={ResendInvitationSuccessPage} />} />
            </Route>
            <Route path=":token" element={<LoginAppWrapper component={SignupContainer} />} />
          </Route>
          <Route path="otp/registration/:token" element={<LoginAppWrapper component={OtpRegistrationContainer} />}/>
          <Route path="password">
            <Route path="forgot">
              <Route element={<LoginAppWrapper component={ForgotPasswordContainer} />} />
              <Route path="success" element={<LoginAppWrapper component={ForgotPasswordSuccessPage} />} />
            </Route>
            <Route path="reset" element={<LoginAppWrapper component={ResetPasswordContainer} />} />
          </Route>
          <Route path="reset" element={<LoginAppWrapper component={ResetPasswordContainer} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
