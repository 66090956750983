import React from 'react'
import get from 'lodash/get'

export default function dynamicPosition(WrappedComponent) {
  return function(props) {

    const enableDynamicPosition = get(props, 'enableDynamicPosition', true)

    function setPositionClass(element, relativeElementClass = null) {
      if(!enableDynamicPosition) {
        return 
      }
      
      let relativeRect
      const rect = element.getBoundingClientRect()

      if(relativeElementClass) {
        const relativeElement = document.getElementsByClassName(relativeElementClass)[0]
        relativeRect = relativeElement.getBoundingClientRect()
      }
      else {
        relativeRect = {
          bottom: window.innerHeight,
          left: 0,
          right: window.innerWidth,
          top: 0
        }
      }

      setOverflowClass(element, 'right', (rect.left + rect.width) > relativeRect.right)
      setOverflowClass(element, 'left', rect.left < relativeRect.left)
      setOverflowClass(element, 'bottom', (rect.top + rect.height) > relativeRect.bottom)
      setOverflowClass(element, 'top', rect.top < relativeRect.top)
    }

    function setOverflowClass(element, direction, isOverflow) {
      if(isOverflow) {
        element.classList.add(`${direction}-overflow`)
      }
      else {
        element.classList.remove(`${direction}-overflow`)
      }
    }

    return (
      <WrappedComponent {...props} setPositionClass={setPositionClass} />
    )
  }
}
