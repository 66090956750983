const SIGNIN_ERRORS = {
  invalid: 'signin_1',
  locked: 'signin_2',
  timeout: 'signin_3',
  not_found_in_database: 'signin_4',
  unconfirmed: 'signin_5',
  invalid_token: 'signin_6',
  invalid_captcha: 'signin_8',
  invalid_otp: 'signin_7',
  inactive: 'signin_14'
}

const ENTER_EMAIL_SIGNUP_ERRORS = {
  account_exists: 'enterEmailSignup_3',
  not_work_email: 'enterEmailSignup_4',
  closed_for_registration: 'enterEmailSignup_5',
  pending_token: 'enterEmailSignup_7',
  invalid_captcha: 'enterEmailSignup_8',
  org_email_not_exist: 'enterEmailSignup_9',
  license_quota_exceeded: 'enterEmailSignup_10'
}

const SIGNUP_ERRORS = {
  invalid_token: 'signup_2',
  password_crackable: 'signup_4',
  revoked_token: 'signup_6',
  account_exists: 'signup_22',
  not_work_email: 'signup_33'
}

const RESET_PASSWORD_ERRORS = {
  incorrect: 'resetPassword_2',
  passwords_must_match: 'resetPassword_3',
  password_crackable: 'resetPassword_4',
  password_expired: 'resetPassword_6',
  password_already_used: 'resetPassword_7',
  token_expired: 'resetPassword_8'
}

const FORGOT_PASSWORD_ERRORS = {
  email_not_exist: 'forgotPassword_10'
}

const OTP_REGISTRATIONS_ERRORS = {
  invalid_details: 'otpRegistration_1'
}

export const ERRORS = {
  signin: SIGNIN_ERRORS,
  enterEmailSignup: ENTER_EMAIL_SIGNUP_ERRORS,
  signup: SIGNUP_ERRORS,
  resetPassword: RESET_PASSWORD_ERRORS,
  forgotPassword: FORGOT_PASSWORD_ERRORS,
  otpRegistration: OTP_REGISTRATIONS_ERRORS
}

export function mapToAppError(moduleName, errorCode) {
  return `${moduleName}_${errorCode}`
}
