import React, {useRef} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import './styles.css.scss'

export default function ResendInvitation(props) {
  const { email, onClick } = props
  const refCaptcha = useRef()
  return (
    <div className='resend-invitation'>
      <div className='captcha-container'>
        <ReCAPTCHA
          ref={refCaptcha}
          sitekey={FollozeState.envConfig.RECAPTCHA_SITE_KEY}
          onChange={props.onCaptchaChange} />
      </div>
      <div className='text-container' >
        <p className='text'>
            There is a pending invitation for
            <br/>
            <b> {props.email} </b>
            <br />
            <br />
            Please check your email again
            <br />
            (Inbox/Trash/Spam folders)
        </p>
        <div className='button-container'>
          <button className='button' onClick={props.onClick} >
            {'Resend'}
          </button>
        </div>
      </div>
    </div>
  )
}
