import isEmpty from 'lodash/isEmpty'

const passwordValidator = () => {
  const VALIDATORS = {
    minChar: {
      isValid: function(value){
        return (value.length >= 8)
      }
    },
    lowerCase: {
      isValid: function(value){
        return value.match(/[a-z]/g)    
      }
    },
    upperCase: {
      isValid: function(value){
        return value.match(/[A-Z]/g)
      }
    },
    symbol: {
      isValid: function(value){
        return value.match(/[|\\/~^:,;?!&%#$@*+]/)  
      }
    }
  }

  const validatePassword = (password) => {
    let validationResult = {
      valids: [],
      invalids: []
    }

    Object.keys(VALIDATORS).forEach(key => {
      let validator = VALIDATORS[key]
      validator.isValid(password) ? 
        validationResult.valids.push(key) : validationResult.invalids.push(key)
    })

    return validationResult
  }
  
  const isValid = (password) => {
    return isEmpty(validatePassword(password).invalids)
  }

  return { validatePassword, isValid }
}

export default passwordValidator()
