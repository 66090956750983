import * as api from 'Login/services/api'
import { REHYDRATE } from 'redux-persist/constants'
import { ERRORS, mapToAppError } from 'Login/helpers/errors'
import { constants as formActionTypes, form } from 'shared/modules/form'
import omit from 'lodash/omit'
import invert from 'lodash/invert'
import TimezoneService from 'common/helpers/TimezoneService'

const timezoneService = TimezoneService()

const FETCHING_REGISTRATION_TOKEN = 'signup/FETCHING_REGISTRATION_TOKEN'
export const FETCHING_REGISTRATION_TOKEN_SUCCESS = 'signup/FETCHING_REGISTRATION_TOKEN_SUCCESS'
export const FETCHING_REGISTRATION_TOKEN_FAILURE = 'signup/FETCHING_REGISTRATION_TOKEN_FAILURE'
export const ACCOUNT_REQUESTED = 'signup/ACCOUNT_REQUESTED'
export const SIGNUP_ERROR = 'signup/SIGNUP_ERROR'
const UPDATE_INPUT = 'signup/UPDATE_INPUT'
const SET_INPUTS = 'signup/SET_INPUTS'
const VALIDATE_FORM = 'signup/VALIDATE_FORM'
const SET_TOKEN = 'signup/SET_TOKEN'
const SET_EMAIL = 'signup/SET_EMAIL'

// ACTIONS

function updateInput(args) {
  return {
    ...args,
    type: UPDATE_INPUT
  }
}

function setInputs(inputs) {
  return {
    inputs: inputs,
    type: SET_INPUTS
  }
}

function setEmail(email) {
  return {
    type: SET_EMAIL,
    email: email
  }
}

function validateForm(args) {
  return {
    ...args,
    type: VALIDATE_FORM
  }
}

function fetchingRegistrationToken() {
  return {
    type: FETCHING_REGISTRATION_TOKEN
  }
}

function fetchingRegistrationTokenSuccess() {
  return {
    type: FETCHING_REGISTRATION_TOKEN_SUCCESS
  }
}

function fetchingRegistrationTokenFailure(error) {
  return {
    type: FETCHING_REGISTRATION_TOKEN_FAILURE,
    error: error
  }
}

function accountRequested(email) {
  return {
    type: ACCOUNT_REQUESTED,
    email: email
  }
}

export function submitSignup({ email, type }) {
  return function (dispatch) {
    return new Promise((resolve) => {
      dispatch(accountRequested(email))
      resolve()
    })
  }
}

function setError(error) {
  return {
    type: SIGNUP_ERROR,
    error: error
  }
}


export function signupError(error) {
  return function (dispatch, getState) {
    const email = getState().signup.email

    dispatch(setError(error))
  }
}

function setToken(token) {
  return {
    type: SET_TOKEN,
    token: token
  }
}

function fetchRegistrationTokenRecord(token) {
  return function (dispatch) {
    dispatch(fetchingRegistrationToken())

    return api.fetchRegistrationToken(token)
      .then(({ data }) => {
        dispatch(fetchingRegistrationTokenSuccess())
        dispatch(setEmail(data.email))
        dispatch(setInputs(data))
      })
      .catch(({ response }) => {
        dispatch(fetchingRegistrationTokenFailure(
          mapToAppError('signup', response.data.error)
        ))
      })
  }
}

export function updateInputAndValidateForm({ fieldName, value, isValid }) {
  return function (dispatch) {
    dispatch(updateInput({ fieldName, isValid, value }))
    dispatch(validateForm({ fieldName, isValid }))
  }
}

export function persist({ form }) {
  return {
    form: {
      inputs: omit(form.inputs, ['password'])
    }
  }
}

export function setAndFetchRegistrationToken(token) {
  return function (dispatch) {
    if (!!token) {
      dispatch(setToken(token))
      dispatch(updateInput({
        fieldName: 'token',
        value: token,
        isValid: true
      }))
      dispatch(fetchRegistrationTokenRecord(token))
    }
  }
}

// REDUCERS

const initialInputState = {
  isValid: false,
  value: '',
  touched: false
}

const initTimezone = {
  isValid: true,
  value: timezoneService.currentTimezone(),
  touched: false
}

const initTos = {
  isValid: true,
  value: true,
  touched: false
}

const initialInputsState = {
  firstName: initialInputState,
  lastName: initialInputState,
  email: initialInputState,
  token: initialInputState,
  password: initialInputState,
  timezone: initTimezone,
  tos: initTos
}

const initialFormState = {
  isValid: false,
  inputs: initialInputsState
}

const initialState = {
  isFetchingLinkedinUser: false,
  isFetchingRegistrationToken: false,
  isValidToken: null,
  token: null,
  email: '',
  error: '',
  form: initialFormState
}

export default function signup(state = initialState, action) {
  switch(action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.token
      }
    case SET_EMAIL:
      return {
        ...state,
        email: action.email
      }
    case SIGNUP_ERROR:
      return {
        ...state,
        error: action.error
      }
    case FETCHING_REGISTRATION_TOKEN:
      return {
        ...state,
        isFetchingRegistrationToken: true
      }
    case FETCHING_REGISTRATION_TOKEN_SUCCESS:
      return {
        ...state,
        isFetchingRegistrationToken: false,
        isValidToken: true
      }
    case FETCHING_REGISTRATION_TOKEN_FAILURE:
      return {
        ...state,
        isFetchingRegistrationToken: false,
        isValidToken: false,
      }
    case REHYDRATE:
      if (state.error === '')
        return state
      else
        return {
          ...state,
          form: form(
            state.form,
            action,
            formActionTypes.REHYDRATE,
            { payload: action.payload.signup.form }
          )
        }
    case UPDATE_INPUT:
      return {
        ...state,
        form: form(state.form, action, formActionTypes.UPDATE_INPUT)
      }
    case SET_INPUTS:
      return {
        ...state,
        form: form(state.form, action, formActionTypes.SET_INPUTS)
      }
    case VALIDATE_FORM:
      return {
        ...state,
        form: form(state.form, action, formActionTypes.VALIDATE_FORM)
      }
    default:
      return state
  }
}
