import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'shared/hocs'
import { queryToParamsObject } from 'shared/helpers/UrlHelper'
import ForgotPassword from './ForgotPassword'
import { mapToAppError } from 'Login/helpers/errors'
import * as forgotPasswordActions from 'Login/modules/forgotPassword'

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isCaptchaValid: false
    }

    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleInvalidForm = this.handleInvalidForm.bind(this)
  }

  componentDidMount() {
    const searchParams = queryToParamsObject(this.props.location.search)
    const error = searchParams.error

    if (!!error)
      this.props.setError(mapToAppError('forgotPassword', error))
  }

  handleFormChange(inputData) {
    this.props.updateInputAndValidateForm(inputData)
  }

  handleInvalidForm(error) {
    this.props.setError(error)
  }

  handleFormSubmit(data, targetForm) {
    const { isCaptchaValid } = this.state

    if(!isCaptchaValid) {
      return
    }

    this.props.submitForgotPassword({...data, type: 'email'})
    targetForm.submit()
  }

  handleCaptchaChange = (response) => {
    this.setState({ isCaptchaValid: !!response })
    this.handleFormChange({
      fieldName: 'captcha',
      value: response,
      isValid: true
    })
  }

  render() {
    return (
      <ForgotPassword
        error={this.props.error}
        form={this.props.form}
        onFormSubmit={this.handleFormSubmit}
        onFormInvalid={this.handleInvalidForm}
        onFormChange={this.handleFormChange}
        onCaptchaChange={this.handleCaptchaChange} />
    )
  }
}

function mapStateToProps ({ forgotPassword }, props) {
  return forgotPassword
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(forgotPasswordActions, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ForgotPasswordContainer))
