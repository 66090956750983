import browser from 'detect-browser'

export const ACCESSIBILITY_KEYS = {
  enter: 'Enter',
  arrowDown: 'ArrowDown'
}

export const ACCESSIBILITY_CLASSES = {
  main: 'accessibility',
  withOffset: 'accessibility negative-offset'
}

export const TAB_INDEX_INDICATIONS = {
  skip: -1,
  normalFlow: 0
}

export const TAB_INDEXES = {
  Header: TAB_INDEX_INDICATIONS.normalFlow,
  CloseButton: TAB_INDEX_INDICATIONS.normalFlow,
  NavigationArrow: TAB_INDEX_INDICATIONS.normalFlow,
  ItemTile: TAB_INDEX_INDICATIONS.normalFlow,
  ItemTileImage: TAB_INDEX_INDICATIONS.skip,
  ItemTileDummyAnchorTag: TAB_INDEX_INDICATIONS.skip,
  LeadingItem: TAB_INDEX_INDICATIONS.normalFlow,
  LeadingItemImage: TAB_INDEX_INDICATIONS.skip,
  LeadingItemDummyAnchorTag: TAB_INDEX_INDICATIONS.skip,
  ShareButton: TAB_INDEX_INDICATIONS.normalFlow,
  BoardSocialLinks: TAB_INDEX_INDICATIONS.normalFlow,
  ShareBoardInputField: TAB_INDEX_INDICATIONS.normalFlow,
  CustomFormField: TAB_INDEX_INDICATIONS.normalFlow,
  ParentCategory: TAB_INDEX_INDICATIONS.normalFlow,
  CustomFooterTrackButton: TAB_INDEX_INDICATIONS.normalFlow,
  ItemModalActionButton: TAB_INDEX_INDICATIONS.normalFlow,
  PromotionItem: TAB_INDEX_INDICATIONS.normalFlow,
  PromotionCallToActionContainer: TAB_INDEX_INDICATIONS.skip,
  SubmitButton: TAB_INDEX_INDICATIONS.normalFlow,
  CarouselIndicator: TAB_INDEX_INDICATIONS.normalFlow,
  CarouselArrow: TAB_INDEX_INDICATIONS.normalFlow,
  SendMessageField: TAB_INDEX_INDICATIONS.normalFlow,
  CheckBox: TAB_INDEX_INDICATIONS.normalFlow,
  Link: TAB_INDEX_INDICATIONS.normalFlow,
  GreetingCardCloseButton: 1,
  LogoFirst: 2,
  LogoSecond: 3,
  SellerCard: 4,
  SocialLink: 5,
  ContactDetailsEmail: 6,
  OpenedCard: 7,
}

export const isEnterKey = (e) => {
  if (e && e.key === ACCESSIBILITY_KEYS.enter) {
    return true
  }
}

export const isDownArrowKey = (e) => {
  if (e && e.key === ACCESSIBILITY_KEYS.arrowDown) {
    return true
  }
}

export const triggerClickOnEnter = (event) => {
  if (isEnterKey(event)) {
    event.currentTarget.click()
  }
}

