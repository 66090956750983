import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'shared/hocs'

import './styles.css.scss'

class LoginApp extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      headerActions: null
    }
  }

  navigateHome = () => {
    this.props.navigate('/signin')
  }

  provideHeaderActions = (component) => {
    const path = this.props.location.pathname
    const loginAction = path.includes('/signup') ? 'signin' : 'signup'

    this.setState(
      Object.assign({
        headerActions: component
      }, LoginApp.navigationType[loginAction])
    )
  }

  render() {
    const HeaderActions = this.state.headerActions

    return (
      <div className='page'>
        <div className='container-left'>
          <div className='login-bcg'>
            <div className='login-title-flz'>
              <div className='flz-logo'
                onClick={this.navigateHome}
                title='Go to Sign in'>
                <img src="https://images.folloze.com/image/upload/v1702821113/LOGO_kawpmu.svg"/>

              </div>
            </div>
            <div className='login-title'>Create impact with every digital touch-point</div>
          </div>
        </div>
        <div className='container-right'>
          <div className='header'>
            { HeaderActions && <HeaderActions
              navigationText={this.state.navigationText}
              navigationLink={this.state.navigationLink}
              navigationLinkText={this.state.navigationLinkText} />
            }
          </div>
          <div className='login-form'>
            {React.cloneElement(this.props.children, {
              provideHeaderActions: this.provideHeaderActions
            })
            }
          </div>
          <div className='footer'>
            <span><a href="https://www.folloze.com/about" target="_blank" rel="noreferrer">About</a></span>
            <span><a href="https://www.folloze.com/blog" target="_blank" rel="noreferrer">Blog</a></span>
            <span><a href="https://help.folloze.com" target="_blank" rel="noreferrer">Help Center</a></span>
          </div>
        </div>
      </div>
    )
  }
}

LoginApp.navigationType = {
  signin: {
    navigationText: 'Already have an account?',
    navigationLink: '/signin',
    navigationLinkText: 'Login'
  },
  signup: {
    navigationText: "Don't have an account?",
    navigationLink: '/signup',
    navigationLinkText: 'Get started'
  }
}

export default withRouter(LoginApp)
