import React from 'react'
import { Link } from 'react-router-dom'

import './styles.css.scss'

export default function ForgotPasswordLink(props) {
  return(
    <div className='forgot-password-link'>
      <Link className='link' to="/password/forgot">
        Forgot your password?
      </Link>
    </div>
  )
}
