import * as api from 'Login/services/api'
import { constants as formActionTypes, form } from 'shared/modules/form'
import { mapToAppError } from 'Login/helpers/errors'
const CREATE_OTP_REGISTRATION_SUCCESS = 'otpRegistration/CREATE_OTP_REGISTRATION_SUCCESS'
const UPDATE_INPUT = 'otpRegistration/UPDATE_INPUT'
const VALIDATE_FORM = 'otpRegistration/VALIDATE_FORM'
const OTP_ERROR = 'otpRegistration/OTP_ERROR'
// ACTIONS
function createOtpRegistrationSuccess({ sent }) {
  return {
    type: CREATE_OTP_REGISTRATION_SUCCESS,
    otpSent: sent
  }
}

function updateInput(args) {
  return {
    ...args,
    type: UPDATE_INPUT
  }
}

function validateForm(args) {
  return {
    ...args,
    type: VALIDATE_FORM
  }
}

function setError(error) {
  return {
    type: OTP_ERROR,
    error: error
  }
}

export function createOtpRegistration(phoneNumber, countryCode) {
  return function (dispatch, getState) {
    const email = getState().enterEmailSignin.email
    return api.createOtpRegistration(email, phoneNumber, countryCode)
      .then(({ data }) => {
        dispatch(createOtpRegistrationSuccess({...data}))
      })
      .catch(({ response }) => {
        dispatch(setError(mapToAppError('otpRegistration', response.data.error)))
      })
  }
}

export function updateInputAndValidateForm({ fieldName, value, isValid }) {
  return function (dispatch) {
    dispatch(updateInput({ fieldName, isValid, value }))
    dispatch(validateForm({ fieldName, isValid }))
  }
}

// REDUCERS
const initialInputState = {
  isValid: false,
  value: '',
  touched: false
}

const initialInputsState = {
  countryCode: initialInputState,
  phoneNumber: initialInputState
}

const initialFormState = {
  isValid: false,
  inputs: initialInputsState
}

const initialState = {
  form: initialFormState,
  error:'',
  otpSent: null
}

export default function otpRegistration(state = initialState, action) {
  switch(action.type) {
    case CREATE_OTP_REGISTRATION_SUCCESS:
      return {
        ...state,
        otpSent: action.otpSent
      }
    case VALIDATE_FORM:
      return {
        ...state,
        form: form(state.form, action, formActionTypes.VALIDATE_FORM)
      }
    case UPDATE_INPUT:
      return {
        ...state,
        form: form(state.form, action, formActionTypes.UPDATE_INPUT)
      }
    case OTP_ERROR:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}