import React, { Component } from 'react'
import PropTypes from 'prop-types'
import passwordValidator from 'shared/UI/Form/passwordValidator'
import sortBy from 'lodash/sortBy'
import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'

import './styles.css.scss'

const INITIAL_STATE = {
  requirements: {
    minChar: {
      key:'minChar',
      description:'8 characters minimum',
      class:'invalid',
      order:1
    },
    lowerCase:{
      key:'lowerCase',
      description:'One lowercase character',
      class:'invalid',
      order:2
    },
    upperCase:{
      key:'upperCase',
      description:'One uppercase character',
      class:'invalid',
      order:3
    },
    symbol:{
      key:'symbol',
      description:'One special character',
      class:'invalid',
      order:4
    }
  }
}

const REQUIREMENTS_ICONS = {
  valid: 'icon-tick-sign',
  invalid: 'icon-bullet'
}

class PasswordValidator extends Component {
  
  constructor(props) {
    super(props)
    this.state = cloneDeep(INITIAL_STATE)
  } 

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.updateValidRequirements(this.props.value)
    }
  }
  
  updateValidRequirements(passwordValue) {
    let passwordRequirements = cloneDeep(INITIAL_STATE.requirements)
    const validRequirementsArr = passwordValidator.validatePassword(passwordValue).valids
    for (let req of validRequirementsArr) {
      passwordRequirements[req].class = 'valid'
    }
    this.setState({
      requirements: passwordRequirements
    })
  }

  renderValidRequirements(validations) {
    return sortBy(validations, 'order').map((field) => {
      return (

        <li key={field.key} className={`icon ${field.class} ${REQUIREMENTS_ICONS[field.class]}`} >{field.description}</li>
      )
    })
  }

  render() {
    return(
      <div className='password-validator'>
        <ul>
          {this.renderValidRequirements(this.state.requirements)}
        </ul>
      </div>
    )
  }
}

PasswordValidator.propTypes = {
  value: PropTypes.string,
}

export default PasswordValidator
